import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import React, { useEffect } from "react";
import CustomButton from "../../shared/CustomButton";
import CustomGrid from "../../shared/CustomGrid";
import bannerImage from "../../assets/images/bg.webp";
import bannerShape from "../../assets/images/banner-shape.svg";
import CustomTypography from "../../shared/CustomTypography";
import mobilePattern from "../../assets/images/mobile-pattern.svg";
import overlayImg from "../../assets/images/footer-layer.webp";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bannerWrap: {
    padding: "100px 0 120px",
    minHeight: "100vh",
    display: "flex",
    alignItems: "flex-end",
    backgroundImage: `url(${bannerImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: [[90, 0, 125]],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[90, 0, 100]],
    },
    "&:before": {
      content: '""',
      position: "absolute",
      left: 40,
      top: 0,
      bottom: 0,
      width: 1,
      backgroundColor: "#fff",
      [theme.breakpoints.down("sm")]: {
        left: 13,
      },
    },
    "&:after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: -1,
      zIndex: 9,
      right: 0,
      backgroundImage: `url(${bannerShape})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      height: 74,
      [theme.breakpoints.between("md", "1441")]: {
        backgroundSize: "contain",
        marginRight: -10,
        backgroundPosition: "bottom center",
      },
      [theme.breakpoints.down("md")]: {
        backgroundImage: `url(${mobilePattern})`,
        transform: "rotate(180deg)",
        height: 80,
      },
      [theme.breakpoints.down("sm")]: {
        backgroundSize: "contain",
        bottom: -25,
      },
    },
  },
  bannerText: {
    "& h1": {
      color: "#fff",
      fontSize: 72,
      margin: 0,
      fontFamily: "Satoshi-Regular",
      lineHeight: "97.2px",
      [theme.breakpoints.down("md")]: {
        fontSize: 40,
        lineHeight: "50px",
        marginBottom: 40,
      },
      [theme.breakpoints.up("xs")]: {
        marginLeft: 10,
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: 30,
      },
      [theme.breakpoints.up("1340")]: {
        marginLeft: 0,
      },
      [theme.breakpoints.down("376")]: {
        fontSize: 40,
      },
      [theme.breakpoints.down("366")]: {
        fontSize: 33,
      },
      "& span": {
        fontFamily: "Satoshi-Bold",
        display: "block",
      },
    },
  },
  bannerBtn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 45.5px",
      [theme.breakpoints.down("md")]: {
        padding: " 8.5px 33px",
      },
      [theme.breakpoints.up("xs")]: {
        marginLeft: 10,
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: 30,
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: 0,
      },
    },
  },
  overlay: {
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.3,
    mixBlendMode: "overlay",
    height: "100%",
    width: "100%",
    filter: "blur(257px)",
    transform: "rotate(-180deg)",
  },
}));

export default function Banner() {
  const classes = useStyles();

  useEffect(() => {
    const element = document.getElementById(`banner`);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  return (
    <div className={classes.bannerWrap} id="banner">
      <img
        src={overlayImg}
        className={classes.overlay}
        alt=""
        height="100%"
        width="100%"
      />
      <Container>
        <CustomGrid container alignItems="flex-end">
          <CustomGrid item xs={12} md={9}>
            <div className={classes.bannerText}>
              <CustomTypography variant="h1" component="h1">
                Inspiring People to <span>Deliver Inspiring Results</span>
              </CustomTypography>
            </div>
          </CustomGrid>
          <CustomGrid item xs={12} md={3} mb={{ md: "19px" }}>
            <Link to="/about">
              <CustomButton
                className={classes.bannerBtn}
                variant="outlined"
                color="primary"
                sx={{ minWidth: 150 }}
              >
                Learn How
              </CustomButton>
            </Link>
          </CustomGrid>
        </CustomGrid>
      </Container>
    </div>
  );
}
