import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import teamImg from "../../assets/images/team-img.webp";
import CustomButton from "../../shared/CustomButton";
import CustomGrid from "../../shared/CustomGrid";
import CustomTypography from "../../shared/CustomTypography";

const useStyles = makeStyles((theme) => ({
  teamsSection: {
    padding: [[100, 0]],
    backgroundImage:
      "linear-gradient(to top, rgba(255,255,255,0)  , rgba(234,234,234,1),rgba(234,234,234,1),rgba(234,234,234,1) )",
      color: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      padding: [[50, 0, 100, 0]],
    },
  },
  teamHeadingM: {
    marginBottom: 100,
    [theme.breakpoints.down("md")]: {
      marginBottom: 44,
    },
  },
  teamsHeading1: {
    "&.MuiTypography-root": {
      fontSize: 31,
      fontFamily: "Satoshi-Bold",
      marginBottom: 34,
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },
    },
  },
  teamsHeading2: {
    "&.MuiTypography-root": {
      fontSize: 72,
      lineHeight: "97px",
      [theme.breakpoints.down("md")]: {
        fontSize: 48,
        lineHeight: "64px",
      },
    },
  },
  teamImg: {
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 24,
    },
  },
  teamInfo: {
    "&.MuiTypography-root": {
      fontSize: 24,
      marginBottom: 34,
    },
  },
  infoMain: {
    [theme.breakpoints.down("md")]: {
      padding: [[0, 0, 0, 150]],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[0, 0, 0, 120]],
    },
    [theme.breakpoints.down("440")]: {
      padding: [[0, 0, 0, 78]],
    },
    [theme.breakpoints.down("360")]: {
      padding: [[0, 0, 0, 50]],
    },
  },
  btn:{
    "&.MuiButtonBase-root.MuiButton-root": {
      padding:" 11.5px 23px",
      [theme.breakpoints.down("md")]: {
        padding:" 8.5px 20px",
      },
    },
  }
}));

const Teams = () => {
  const classes = useStyles();
  return (
    <div className={classes.teamsSection}>
      <Container>
        <div className={classes.teamHeadingM}>
          <CustomTypography variant="h4" className={classes.teamsHeading1}>
            Vasana for Teams
          </CustomTypography>
          <CustomTypography variant="h2" className={classes.teamsHeading2}>
            Better Teams equal <br></br> Exceptional Performance
          </CustomTypography>
        </div>
        <div>
          <CustomGrid container alignItems='flex-end' spacing={{ xs: 5.5, md: 10 }}>
            <CustomGrid item xs={12} md={6}>
              <div className={classes.teamImg}>
                <img src={teamImg} alt="team" />
              </div>
            </CustomGrid>
            <CustomGrid item xs={12} md={6}>
              <div className={classes.infoMain}>
                <CustomTypography variant="body1" className={classes.teamInfo}>
                  We shape working environments where relationships thrive. Our
                  science-backed analytics powered by the Vasana Behavioral
                  Genome and our proprietary and patented tools mold leaders,
                  make teams resilient, and transform organizations.
                </CustomTypography>
                <CustomButton className={classes.btn}  variant="outlined" color="primary">
                  <Link to="/vasana">Transform Your Company with Vasana</Link>
                </CustomButton>
              </div>
            </CustomGrid>
          </CustomGrid>
        </div>
      </Container>
    </div>
  );
};

export default Teams;
