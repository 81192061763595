import { Container } from "@mui/system";
import React from "react";
import { makeStyles } from "@mui/styles";
import CustomGrid from "../../shared/CustomGrid";
import CustomTypography from "../../shared/CustomTypography";
import diagram from "../../assets/images/diagram.png";

const useStyles = makeStyles((theme) => ({
  evolution: {
    padding: [[100, 0, 150,0]],
    [theme.breakpoints.down("md")]: {
      padding: [[60, 0, 80,0]],
    },
  },
  evolutionInfo: {
    "& h3": {
      fontSize: 42,
      fontFamily: "Satoshi-Bold",
      marginBottom: 44,
      lineHeight: 1.3,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
      },
    },
  },
  infoMain: {
    maxWidth: 650,
    [theme.breakpoints.down("lg")]: {
      paddingRight: 0,
    },
    "& p": {
      fontSize: 20,
      marginBottom: 40,
      lineHeight: 1.5,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        marginBottom: 20,
      },
      "&:last-child": {
        marginBottom: 0,
      }
    },
  },
  range1: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      margin: [[60, 0]],
      display: "block",
      textAlign: "center",
    },
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
  },
  range2: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& img": {
      height: "auto",
      maxWidth: "100%",
    },
  },
  diagram:{
    height: "auto",
  },
}));

const Evolution = () => {
  const classes = useStyles();
  const info = [
    {
      info: "We collaborate with you to create winning ways of working enabling you to move faster, be nimble, grow quickly, innovate smarter, and create a sense of belonging.",
      img: diagram
    },
    {
      info:"Our rapid, iterative delivery model accelerates time to value with 90-day timeboxes."
    },
    {
      info:'We deliver impact that transforms organizations, accelerating performance and setting the stage for sustainable growth.'
    }
  ];
  return (
    <div className={classes.evolution}>
      <Container>
        <CustomGrid container spacing={3} alignItems="center">
          <CustomGrid item xs={12} md={7.5}>
            <div className={classes.evolutionInfo}>
              <CustomTypography component="h3" variant="h3">
                Embrace Evolution, Ignite Innovation
              </CustomTypography>
              <div className={classes.infoMain}>
                {info.map((data, i) => {
                  return (
                    <>
                      <CustomTypography variant="body1" component="p" key={i}>
                        {data.info}
                      </CustomTypography>
                     {data.img && <div className={classes.range1}>
                        <img src={data.img} alt="img" height="187" width="446" />
                      </div>}
                    </>
                  );
                })}
              </div>
            </div>
          </CustomGrid>
          <CustomGrid sx={{display:{xs: 'none', md:'block'} }} item xs={12} md={4.5}>
            <div className={classes.range2}>
              <img src={diagram} className={classes.diagram} alt="img" height="187" width="446" />
            </div>
          </CustomGrid>
        </CustomGrid>
      </Container>
    </div>
  );
};

export default Evolution;
