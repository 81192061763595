import React from "react";
import HomePageComponent from "../components/Home";

export default function Home() {
  return (
    <div>
      <HomePageComponent />
    </div>
  );
}
