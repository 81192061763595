import { makeStyles } from "@mui/styles";
import { Container, Link } from "@mui/material";
import React from "react";
import CustomGrid from "../../shared/CustomGrid";
import bannerShape from "../../assets/images/banner-shape.svg";
import CustomTypography from "../../shared/CustomTypography";
import playIcon from "../../assets/images/play.svg";
import bgImg from "../../assets/images/what-we-do-bg.png";
import PauseIcon from '@mui/icons-material/Pause';
import { Box } from "@mui/material";
import videoMp4 from "../../assets/videos/Who-We-Are.mp4";
import videoMov from "../../assets/videos/Who-We-Are.MOV";
import videoMpeg from "../../assets/videos/Who-We-Are.mpeg";
import videoPoster from "../../assets/images/video-poster.png";
import mobilePattern from "../../assets/images/mobile-pattern.png";

const useStyles = makeStyles((theme) => ({
  bannerWrap: {
    padding: "100px 0 115px",
    minHeight: "100vh",
    backgroundColor: "#EAEAEA",
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: [[90, 0, 140]],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[90, 0, 60]],
    },
    "&:before": {
      content: '""',
      position: "absolute",
      left: 40,
      top: 0,
      bottom: 0,
      width: 1,
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down("sm")]: {
        left: 13,
      },
    },
    "&:after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      right: 0,
      backgroundImage: `url(${bannerShape})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      height: 74,
      [theme.breakpoints.down("md")]: {
        backgroundImage: `url(${mobilePattern})`,
        transform: "rotate(180deg)",
        height: 80,
      },
      [theme.breakpoints.down("sm")]: {
        backgroundSize: "contain",
        bottom: -25,
      },
    },
  },
  bannerText: {
    [theme.breakpoints.down("1340")]: {
      marginLeft: 26,
      marginBottom: 30,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
      marginBottom: 30,
    },
    "& h1": {
      color: theme.palette.secondary.main,
      fontSize: 72,
      margin: [[0, 0, 30, 0]],
      fontWeight: 400,
      lineHeight: "97px",
      [theme.breakpoints.down("1025")]: {
        fontSize: 60,
        lineHeight: '85px',
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 40,
        lineHeight: '55px',
      },
      [theme.breakpoints.down("376")]: {
        fontSize: 35,
      },
    },
    "& p": {
      fontSize: 24,
      marginBottom: 30,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },

      "& span": {
        fontFamily: "Satoshi-Bold",
        fontStyle: "italic",
      },
    },
  },
  bannerImage: {
    width: 400,
    height: 600,
    borderRadius: 20,
    overflow: "hidden",
    position: "relative",
    "& .active":{
      transition: "all 0.5s",
      display: "none"
    },
    "& svg":{
      height: 32,    
      width: 32,
      fill: "#fff",
    },
    "&:hover .active":{
      transition: "all 0.5s",
      display: "flex",
    },
    "& video":{
      objectFit: "cover",
      height: "100%",
      width: "100%",
    },
    [theme.breakpoints.down("1200")]: {
      width: 350,
      height: 490,
    },
    [theme.breakpoints.down("970")]: {
      width: 330,
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
    [theme.breakpoints.down("500")]: {
      width: 253,
      height: 355,
    },
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 24,
  },
  videoIcon: {
    width: 100,
    height: 100,
    backgroundColor: "rgba(8, 75, 131, 0.5)",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    border: "1px solid #084B83",
    justifyContent: "center",
    position: "absolute",
    transform: "translate(-50%,-50%  )",
    left: "50%",
    top: "50%",
    [theme.breakpoints.down("425")]: {
      width: 80,
      height: 80,
    },
  },
  videoText: {
    textAlign: "center",
    "& p": {
      fontFamily: "Satoshi-Medium",
      color: theme.palette.secondary.white,
      [theme.breakpoints.down("425")]: {
        fontSize: 12,
      },
    },
  },
  bgImg: {
    position: "absolute",
    right: "0",
    bottom: 0,
    pointerEvents: 'none',
    maxWidth: '70%',
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export default function Banner() {
  const classes = useStyles();
  const [startVideo,setStartVideo]=React.useState(false)
  const handlePlayVideo=()=>{
    setStartVideo(!startVideo)
  }
  const vidRef=React.useRef();

React.useEffect(() => { 
  if(startVideo){
    vidRef.current.play()
  }else{
    vidRef.current.pause()
  }
},[startVideo]);

  return (
    <div className={classes.bannerWrap}>
      <img src={bgImg} className={classes.bgImg} alt="img" />
      <Container>
        <Box mt={{md:12.2, xs: 10}}>
          <CustomGrid container spacing={{ xs: 3, md: 6 }} alignItems="center">
            <CustomGrid item xs={12} md={7}>
              <div className={classes.bannerText}>
                <CustomTypography variant="h1" component="h1">
                  Hello, we are the <br></br> Anti-Consulting <br></br>{" "}
                  Consultancy
                </CustomTypography>
                <CustomTypography variant="body1" component="p">
                  Hear why from our founder, <span>Veresh Sita</span>{" "}
                </CustomTypography>
              </div>
            </CustomGrid>
            <CustomGrid item xs={12} md={5}>
              <div className={classes.bannerImage}>
              <video autoPlay={startVideo} playsInline ref={vidRef}  poster={videoPoster} >
                <source src={videoMp4} type="video/mp4" />                
                <source src={videoMov} type="video/mp4" />               
                <source src={videoMpeg} type="video/mpeg" />                 
              </video> 
                <Link sx={{ textDecoration: "none", cursor: "pointer" }} onClick={handlePlayVideo}>
                  <div className={`${classes.videoIcon} ${startVideo?"active":"noactive"}`}>
                      <div className={classes.videoText}>
                        {
                          !startVideo ? 
                          <img src={playIcon} alt="play"/>
                          :
                          <PauseIcon/>
                        }
                        <CustomTypography variant="body1" component="p">
                          Video
                        </CustomTypography>
                      </div>
                  </div>
                </Link>
              </div>
            </CustomGrid>
          </CustomGrid>
        </Box>
      </Container>
    </div>
  );
}
