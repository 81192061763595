import React from 'react'
import Banner from './banner'
import Evolution from './Evolution'
import InfoSection from './InfoSection'
import Solution from './Solution'

const AboutPageComponent = () => {
  return (
    <>
      <Banner/>
      <Evolution/>
      <InfoSection/>
      <Solution/>
    </>

  )
}

export default AboutPageComponent