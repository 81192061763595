import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import CustomTypography from "../../shared/CustomTypography";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { ReactComponent as Location } from "../../assets/images/location.svg";
import { ReactComponent as Clock } from "../../assets/images/clock.svg";
import ColorImg from "../../assets/images/footer-layer.webp";

const useStyles = makeStyles((theme) => ({
  OpenRolesMain: {
    padding: [[140, 0, 0, 0]],
    [theme.breakpoints.down("md")]: {
      padding: [[40, 0, 23, 0]],
    },
  },
  heading: {
    "&.MuiTypography-root": {
      lineHeight: "250px",
      fontSize: 162,
      fontWeight: 400,
      fontFamily: "Kalam",
      color: "#EF767A",
      textAlign: "center",
      paddingBottom: 251,
      position: "relative",
      [theme.breakpoints.down("md")]: {
        fontSize: 100,
        lineHeight: "110px",
        paddingBottom: 150,
      },
      "& span": {
        fontWeight: 700,
      },
    },
  },
  img: {
    position: "absolute",
    transform: "translate(-50%,-50%) rotate(90deg) ",
    left: "50%",
    top: "20%",
    zIndex: -1,
    width: 400,
    height: 400,
    [theme.breakpoints.down("md")]: {
      width: 200,
      height: 200,
    },
    "& img": {
      filter: "blur(120px)",
      objectFit: "cover",
      width: "100%",
      height: "100%",
      opacity: "80%",
      [theme.breakpoints.down("md")]: {
        filter: "blur(60px)",
      },
    },
  },
  openRolTitle: {
    "&.MuiTypography-root": {
      fontSize: 42,
      fontFamily: "Satoshi-Bold",
      textAlign: "center",
      marginBottom: 80,
      lineHeight: "57px",
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        marginBottom: 50,
      },
    },
  },
  departmantMain: {
    backgroundColor: theme.palette.secondary.light,
    padding: 24,
    borderRadius: 24,
    [theme.breakpoints.down("md")]: {
      padding: 20,
    },
    "&:not(:last-child)": {
      marginBottom: 24,
    },
  },
  department: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginBottom: 15,
    },
    justifyContent: "space-between",
    "& h6": {
      fontFamily: "Satoshi-Medium",
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
    "& .MuiButtonBase-root": {
      "& svg": {
        marginLeft: 5,
        verticalAlign: "text-top",
      },
    },
  },
  jobTitle: {
    "&.MuiTypography-root": {
      fontSize: 36,
      fontFamily: "Satoshi-Medium",
      marginBottom: 25,
      [theme.breakpoints.down("md")]: {
        fontSize: 30,
      },
    },
  },
  jobInfo: {
    display: "flex",
    "& p": {
      marginRight: 45,
      [theme.breakpoints.down("md")]: {
        marginRight: 40,
      },
      fontFamily: "Satoshi-Medium",
      "& svg": {
        verticalAlign: "text-bottom",
        marginRight: 5,
      },
    },
  },
  allInfoMain: {
    backgroundImage: "linear-gradient( rgba(255,255,255,0)  , rgba(234,234,234,1),rgba(234,234,234,1),rgba(234,234,234,1), rgba(255,255,255,0.10 ) )",
    paddingBottom: 140,
  },
  informationBox: {
    padding: [[0, 70]],
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  btn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 36.5px",
      [theme.breakpoints.down("md")]: {
        padding: " 8.5px 26px",
      },
      "& svg": {
        verticalAlign: "text-top",
      },
    },
  },
}));

const OpenRoles = () => {
  const classes = useStyles();
  const openRol = [
    {
      departmantName: "Department Name",
      btn: "View Job",
      Btnicon: <NorthEastIcon fontSize="small" />,
      jobTitle: "Job title goes here",
      subInfo: [
        {
          icon: <Location fontSize="medium" />,
          text: "100% Remote",
        },
        {
          icon: <Clock fontSize="small" />,
          text: "Full time",
        },
      ],
    },
    {
      departmantName: "Department Name",
      btn: "View Job",
      Btnicon: <NorthEastIcon fontSize="small" />,
      jobTitle: "Job title goes here",
      subInfo: [
        {
          icon: <Location fontSize="medium" />,
          text: "100% Remote",
        },
        {
          icon: <Clock fontSize="small" />,
          text: "Full time",
        },
      ],
    },
    {
      departmantName: "Department Name",
      btn: "View Job",
      Btnicon: <NorthEastIcon fontSize="small" />,
      jobTitle: "Job title goes here",
      subInfo: [
        {
          icon: <Location fontSize="medium" />,
          text: "100% Remote",
        },
        {
          icon: <Clock fontSize="small" />,
          text: "Full time",
        },
      ],
    },
    {
      departmantName: "Department Name",
      btn: "View Job",
      Btnicon: <NorthEastIcon fontSize="small" />,
      jobTitle: "Job title goes here",
      subInfo: [
        {
          icon: <Location fontSize="medium" />,
          text: "100% Remote",
        },
        {
          icon: <Clock fontSize="small" />,
          text: "Full time",
        },
      ],
    },
  ];
  return (
    <div className={classes.OpenRolesMain}>
      <Container>
        <CustomTypography variant="h2" component="h2" className={classes.heading}>
          We are <span>Human</span>{" "}
          <div className={classes.img}>
            <img src={ColorImg} alt="" height="400" width="400" />
          </div>
        </CustomTypography>
      </Container>
      {/* <div className={classes.allInfoMain} id="open-roles">
        <Container>
          <CustomTypography
            className={classes.openRolTitle}
            variant="h3"
            component="h3"
          >
            Open Roles
          </CustomTypography>
          <div className={classes.informationBox}>
            {openRol.map((data, i) => {
              return (
                <div key={i} className={classes.departmantMain}>
                  <div className={classes.department}>
                    <CustomTypography variant="h6" component="h6">
                      {data.departmantName}
                    </CustomTypography>
                    <CustomButton
                      sx={{ display: { xs: "none", md: "block" } }}
                      variant="outlined"
                      className={classes.btn}
                    >
                      {data.btn} {data.Btnicon}{" "}
                    </CustomButton>
                  </div>
                  <CustomTypography
                    className={classes.jobTitle}
                    variant="h4"
                    component="h4"
                  >
                    {data.jobTitle}
                  </CustomTypography>
                  <div className={classes.jobInfo}>
                    {data?.subInfo?.map((data, i) => {
                      return (
                        <div key={i}>
                          <CustomTypography variant="body1" component="p">
                            {" "}
                            {data.icon} {data.text}
                          </CustomTypography>
                        </div>
                      );
                    })}
                  </div>
                  <CustomButton
                    sx={{
                      display: { xs: "block", md: "none" },
                      marginTop: { xs: 4 },
                      marginLeft: "auto",
                    }}
                    variant="outlined"
                    className={classes.btn}
                  >
                    {data.btn} {data.Btnicon}{" "}
                  </CustomButton>
                </div>
              );
            })}
          </div>
        </Container>
      </div> */}
    </div>
  );
};

export default OpenRoles;
