import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import React from "react";
import CustomGrid from "../../shared/CustomGrid";
import bannerShape from "../../assets/images/banner-shape.svg";
import CustomTypography from "../../shared/CustomTypography";
import playIcon from "../../assets/images/play.svg";
import PauseIcon from "@mui/icons-material/Pause";
import bgImg from "../../assets/images/what-we-do-bg.png";
import { Box } from "@mui/material";
import videoMp4 from "../../assets/videos/What-We-Do.mp4";
import videoMov from "../../assets/videos/What-We-Do.MOV";
import videoMpeg from "../../assets/videos/What-We-Do.mpeg";
import videoPoster from "../../assets/images/video-poster.png";
import mobilePattern from "../../assets/images/mobile-pattern.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bannerWrap: {
    padding: "100px 0 130px",
    minHeight: "100vh",
    backgroundColor: "#EAEAEA",
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
    zIndex: 20,
    [theme.breakpoints.down("md")]: {
      padding: [[90, 0, 100]],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[90, 0, 60]],
    },
    "&:before": {
      content: '""',
      position: "absolute",
      left: 40,
      top: 0,
      bottom: 0,
      width: 1,
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down("sm")]: {
        left: 13,
      },
    },
    "&:after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: -1,
      right: 0,
      backgroundImage: `url(${bannerShape})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      height: 65,
      [theme.breakpoints.down("md")]: {
        backgroundImage: `url(${mobilePattern})`,
        transform: "rotate(180deg)",
        height: 80,
      },
      [theme.breakpoints.down("sm")]: {
        backgroundSize: "contain",
        bottom: -25,
      },
    },
  },
  bannerText: {
    [theme.breakpoints.down("1340")]: {
      marginLeft: 26,
      marginBottom: 30,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
      marginBottom: 26,
    },
    "& h1": {
      color: theme.palette.secondary.main,
      fontSize: 72,
      margin: [[0, 0, 40, 0]],
      fontWeight: 400,
      lineHeight: "97PX",
      [theme.breakpoints.down("1025")]: {
        fontSize: 60,
        lineHeight: "85px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 40,
        lineHeight: "55px",
        margin: [[0, 0, 30, 0]],
      },
      [theme.breakpoints.down("376")]: {
        fontSize: 35,
        margin: [[0, 0, 20, 0]],
      },
    },
    "& p": {
      fontSize: 24,
      marginBottom: 40,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        marginBottom: 34,
      },
      "& span": {
        fontFamily: "Satoshi-Bold",
        fontStyle: "italic",
      },
    },
  },
  bannerImage: {
    width: 400,
    height: 600,
    position: "relative",
    borderRadius: 24,
    overflow: "hidden",
    "& .active": {
      transition: "all 0.5s",
      display: "none",
    },
    "& svg": {
      height: 32,
      width: 32,
      fill: "#fff",
    },
    "&:hover .active": {
      transition: "all 0.5s",
      display: "flex",
    },
    "& video": {
      objectFit: "cover",
      height: "100%",
      width: "100%",
    },
    [theme.breakpoints.down("1200")]: {
      width: 350,
      height: 490,
    },
    [theme.breakpoints.down("970")]: {
      width: 330,
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
    [theme.breakpoints.down("500")]: {
      width: 253,
      height: 355,
    },
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 24,
  },
  videoIcon: {
    width: 100,
    height: 100,
    backgroundColor: "rgba(8, 75, 131, 0.5)",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    border: "1px solid #084B83",
    justifyContent: "center",
    position: "absolute",
    transform: "translate(-50%,-50%  )",
    left: "50%",
    top: "50%",
    [theme.breakpoints.down("425")]: {
      width: 80,
      height: 80,
    },
    "& a": {
      textDecoration: "none",
    },
  },
  videoText: {
    textAlign: "center",
    "& p": {
      fontFamily: "Satoshi-Medium",
      color: theme.palette.secondary.white,
      [theme.breakpoints.down("425")]: {
        fontSize: 12,
      },
    },
  },
  bgImg: {
    position: "absolute",
    height: "auto",
    right: "0",
    bottom: 0,
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export default function Banner() {
  const classes = useStyles();
  const [startVideo, setStartVideo] = React.useState(false);
  const handlePlayVideo = () => {
    setStartVideo(!startVideo);
  };
  const vidRef = React.useRef();

  React.useEffect(() => {
    if (startVideo) {
      vidRef.current.play();
    } else {
      vidRef.current.pause();
    }
  }, [startVideo]);
  return (
    <div className={classes.bannerWrap}>
      <img src={bgImg} className={classes.bgImg} alt="img" width="1102" height="883" />
      <Container>
        <Box mt={{ md: 12.2, sm: 10, xs: 5 }}>
          <CustomGrid container spacing={{ md: 8, xs: 3 }} alignItems="center">
            <CustomGrid item xs={12} md={7}>
              <div className={classes.bannerText}>
                <CustomTypography variant="h1" component="h1">
                  What Does <br></br> Reimagining Our <br></br> Way of Working <br></br> Mean?
                </CustomTypography>
                <CustomTypography variant="body1" component="p">
                  Our founder <span>Veresh Sita</span> sheds some light.
                </CustomTypography>
              </div>
            </CustomGrid>
            <CustomGrid item xs={12} md={5}>
              <div className={classes.bannerImage}>
                <video autoPlay={startVideo} playsInline ref={vidRef} poster={videoPoster}>
                  <source src={videoMp4} type="video/mp4" />
                  <source src={videoMov} type="video/mp4" />
                  <source src={videoMpeg} type="video/mpeg" />
                </video>
                <Link sx={{ textDecoration: "none", cursor: "pointer" }} onClick={handlePlayVideo}>
                  <div className={`${classes.videoIcon} ${startVideo ? "active" : "noactive"}`}>
                    <div className={classes.videoText}>
                      {!startVideo ? <img src={playIcon} alt="play" /> : <PauseIcon />}
                      <CustomTypography variant="body1" component="p">
                        Video
                      </CustomTypography>
                    </div>
                  </div>
                </Link>
              </div>
            </CustomGrid>
          </CustomGrid>
        </Box>
      </Container>
    </div>
  );
}
