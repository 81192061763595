import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import React from "react";
import bgImg from "../../assets/images/careers-bg.png";
import CustomTypography from "../../shared/CustomTypography";

const useStyles = makeStyles((theme) => ({
  bannerWrap: {
    padding: "100px 0",
    position: "relative",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: [[90, 0, 70]],
    },
  },
  bannerText: {
    "& h3": {
      color: theme.palette.secondary.main,
      fontSize: 42,
      margin: [[0, 0, 30, 0]],
      fontFamily: "Satoshi-Bold",
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
      },
    },
    "& p": {
      fontSize: 20,
      marginBottom: 40,
      maxWidth: 819,
      lineHeight: "27px",
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        marginBottom: 30,
      },
    },
    "& h6": {
      fontFamily: "Satoshi-Medium",
      marginBottom: 24,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  },
  bgImg: {
    position: "absolute",
    right: "0",
    height: "auto",
    top: "0",
    zIndex: -1,
  },
  btn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 59px",
      [theme.breakpoints.down("md")]: {
        padding: " 8.5px 44px",
      },
    },
  },
}));

export default function Banner() {
  const classes = useStyles();
  return (
    <div className={classes.bannerWrap}>
      <img src={bgImg} className={classes.bgImg} alt="img" width="797" height="1180" />
      <Container>
        <Box mt={{ md: 12.2, xs: 10 }}>
          <div className={classes.bannerText}>
            <CustomTypography variant="h3" component="h3">
              Work Will Never Be More Fun
            </CustomTypography>
            <CustomTypography variant="body1" component="p">
              RedAzure is home to an eclectic mix of people with diverse
              interests, backgrounds, skill sets, and Netflix watchlists. That
              means there is always something new to learn, discuss and bond
              over. Need we say more?{" "}
            </CustomTypography>
            {/* <a href="#open-roles">
            <CustomButton
              className={classes.btn}
              variant="outlined"
              color="primary"
            >
              Join Us
            </CustomButton>
            </a> */}
          </div>
        </Box>
      </Container>
    </div>
  );
}
