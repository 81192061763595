import React from "react";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";
import { Swiper } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";

function CustomSwiper({ children, ...rest }) {
  return (
    <Swiper modules={[Navigation, Pagination, A11y, Autoplay]} {...rest}>
      {children}
    </Swiper>
  );
}

export default CustomSwiper;
