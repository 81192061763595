import React from 'react'
import WhoWeAreComponent from '../components/WhoWeAre'


const WhoWeAre = () => {
  return (
    <WhoWeAreComponent/>
  )
}

export default WhoWeAre