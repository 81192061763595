import React from "react";
import CustomGrid from "../../shared/CustomGrid";
import { makeStyles } from "@mui/styles";
import { Box, Container, Stack } from "@mui/material";
import CustomTypography from "../../shared/CustomTypography";
import bgPurple from "../../assets/images/back-card.png";
import orangeLines from "../../assets/images/orange-lines.png";
import weWork from "../../assets/images/weWorkLogo.png";
import colliers from "../../assets/images/colliersLogo.png";
import quote from "../../assets/images/quote.svg";
import colorLayer from "../../assets/images/footer-layer.webp";

const useStyles = makeStyles((theme) => ({
  bannerWrap: {
    padding: "100px 0",
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.main,

    [theme.breakpoints.down("md")]: {
      padding: [[90, 0, 100]],
    },
    [theme.breakpoints.down("480")]: {
      overflow: "hidden",
    },
  },
  weWorkLogo: {
    maxWidth: "31.5%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "40%",
    },
  },
  colliers: {
    maxWidth: "26.5%",
    marginTop: "-10px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "30%",
      marginTop: 0,
    },
  },
  heading: {
    "&.MuiTypography-h3": {
      color: theme.palette.secondary.main,
      fontSize: 42,
      marginBottom: 30,
      fontFamily: "Satoshi-Bold",
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        marginBottom: 20,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
      },
    },
  },
  card: {
    padding: 30,
    borderRadius: 20,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 12px",
    },
    "& h5": {
      color: theme.palette.black.primary,
      fontSize: 22,
      fontFamily: "Satoshi-Bold",
      lineHeight: "30px",
      [theme.breakpoints.down("lg")]: {
        fontSize: 20,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        lineHeight: "24px",
      },
    },
    "& h4": {
      fontSize: 30,
      lineHeight: "40px",
      fontWeight: 600,
      color: theme.palette.black.primary,
      marginBottom: 15,
      [theme.breakpoints.down("lg")]: {
        fontSize: 26,
        marginBottom: 10,
      },
    },
    "& .desc": {
      fontSize: 18,
      lineHeight: "30px",
      color: theme.palette.secondary.main,
      maxWidth: 500,
      position: "relative",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "21px",
      },
    },
    "& h6": {
      fontSize: 20,
      lineHeight: "27px",
      fontFamily: "Satoshi-Medium",
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        fontSize: 15,
        lineHeight: "21px",
        textAlign: "left",
      },
    },
  },
  quoteSvg: {
    position: "absolute",
    maxWidth: "63px",
    width: "100%",
    [theme.breakpoints.down(575)]: {
      maxWidth: 36,
    },
  },
  quoteSvgStart: {
    top: "-10px",
    left: "-10px",
  },
  quoteSvgEnd: {
    rotate: "180deg",
  },
  purple: {
    background: `url(${bgPurple}) no-repeat center center`,
    backgroundSize: "cover",
  },
  orangeLine: {
    background: `url(${orangeLines}) no-repeat center center`,
    backgroundSize: "cover",
  },
  colorImage: {
    position: "absolute",
    filter: "blur(140px)",
    zIndex: -1,
    transform: "rotate(80deg)",
    bottom: "10%",
    maxWidth: "480px",
    width: "100%",
    height: "480px",
    left: "-5%",
    opacity: 0.3,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
const CompanyCard = () => {
  const classes = useStyles();
  return (
    <Box position="relative">
      <Container>
        <CustomTypography variant="h3" className={classes.heading} textAlign="center">
          Companies We’ve Helped Transform
        </CustomTypography>
        <CustomGrid container spacing={4.375} justifyContent="center">
          <CustomGrid item md={6} sm={10} xs={12}>
            <Stack className={`${classes.card} ${classes.purple}`}>
              <img src={weWork} alt="travis" className={classes.weWorkLogo} />
              <CustomTypography variant="h5" mt={{ md: 3.75, xs: 2 }}>
                Trained 2500 people internationally in 2.5 years
              </CustomTypography>
              <CustomTypography className="desc" my={1.8}>
                <img src={quote} alt="shape" className={`${classes.quoteSvg} ${classes.quoteSvgStart}`} />
                “Market Force has been critical to the ongoing growth and engagement of our people over years full of shifting priorities, extreme
                pressure and a number of changes to the work environment.”
                <img src={quote} alt="shape" className={`${classes.quoteSvg} ${classes.quoteSvgEnd}`} />
              </CustomTypography>
              <CustomTypography variant="h6" className={classes.smHeading} mt="auto" pt={1.9}>
                David Fano, Fmr. Chief Growth Officer
              </CustomTypography>
            </Stack>
          </CustomGrid>
          <CustomGrid item md={6} sm={10} xs={12}>
            <Stack className={`${classes.card} ${classes.orangeLine}`}>
              <img src={colliers} alt="travis" className={classes.colliers} />

              <CustomTypography variant="h5" mt="16px">
                Trained 8000 people internationally in 10 years
              </CustomTypography>

              <CustomTypography className="desc" mt={1.8}>
                <img src={quote} alt="shape" className={`${classes.quoteSvg} ${classes.quoteSvgStart}`} />
                Of all the courses in the Colliers University core curriculum, Market Force has shown the highest correlation with accelerated revenue
                growth and improved performance. Individuals and teams in every service line have dramatically transformed their results with these
                tools.
                <img src={quote} alt="shape" className={`${classes.quoteSvg} ${classes.quoteSvgEnd}`} />
              </CustomTypography>
              <CustomTypography variant="h6" className={classes.smHeading} mt="auto" pt={1.9}>
                Katherine Steen, Fmr. Director, Colliers University
              </CustomTypography>
            </Stack>
          </CustomGrid>
        </CustomGrid>
      </Container>
      <img src={colorLayer} alt="colorLayer" className={classes.colorImage} />
    </Box>
  );
};

export default CompanyCard;
