/* eslint-disable react-hooks/exhaustive-deps */
import { Container, List, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Autoplay, Navigation, Pagination, Thumbs } from "swiper";
import { SwiperSlide } from "swiper/react";
import listImg1 from "../../assets/images/solution-1.svg";
import listImg2 from "../../assets/images/solution-2.svg";
import listImg3 from "../../assets/images/solution-3.svg";
import CustomButton from "../../shared/CustomButton";
import CustomGrid from "../../shared/CustomGrid";
import CustomSwiper from "../../shared/CustomSwiper";
import CustomTypography from "../../shared/CustomTypography";

const useStyles = makeStyles((theme) => ({
  solutionMain: {
    padding: [[100, 0, 160,0]],
    background:
      "linear-gradient( rgba(255,255,255,0.10)  , rgba(216,216,216,1),rgba(202,202,202,1), rgba(255,255,255,0.10) )",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      padding: [[90, 0, 110,0]],
    },
  },
  solutionHeading: {
    "&.MuiTypography-root": {
      fontSize: 42,
      fontFamily: "Satoshi-Bold",
      marginBottom: 80,
      padding: [[0, 20]],
      lineHeight: 1.3,
      color: theme.palette.secondary.main,

      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        marginBottom: 62,

      },
    },
  },
  solutionSlide: {
    padding: [40],
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 24,
    height: "auto",
    [theme.breakpoints.down("601")]: {
      padding: [[40, 20]],
    },
  },
  mainHeading: {
    "&.MuiTypography-root": {
      fontSize: 64,
      fontFamily: "Satoshi-Medium",
      color: theme.palette.secondary.main,
      "& sup":{
        fontFamily: 'Satoshi-Regular',
        marginLeft: -15,
        [theme.breakpoints.down("md")]: {
          marginLeft: -10,
        },
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 42,
      },
    },
  },
  infoText: {
    "&.MuiTypography-root": {
      fontSize: 24,
      marginBottom: 50,
      color: theme.palette.secondary.main,
      maxWidth: 738,
      lineHeight: "32px",
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
        lineHeight: "30px",
      },
    },
  },
  listMainHeading: {
    display: "flex",
    "& h5": {
      fontSize: 20,
      fontFamily: "Satoshi-Medium",
      color: theme.palette.secondary.main,
      marginBottom: 8,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  solutionsIcon: {
    marginTop: -16,
    flex: "none",
  },
  solutionSlider: {
    paddingBottom: 75,
    position: "relative",
    marginLeft: "-15%",
    marginRight: "-15%",
    [theme.breakpoints.down("xl")]: {
      marginLeft: "-25%",
      marginRight: "-25%",
    },
    [theme.breakpoints.down("1280")]: {
      marginLeft: "-30%",
      marginRight: "-30%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-35%",
      marginRight: "-35%",
    },
    "& .swiper-button-next": {
      background: '#084B83',
      height: 40,
      width: 40,
      top: 'calc(50% - 37.5px)',
      zIndex: 99,
      right: '12%',
      [theme.breakpoints.down("xl")]: {
        right: '17%',
      },
      [theme.breakpoints.down("1280")]: {
        right: '21%',
      },
      [theme.breakpoints.down("sm")]: {
        height: 25,
        width: 25,
      },
      "&:after":{
        color: "#fff",
        fontSize: 27, 
        [theme.breakpoints.down("sm")]: {
          fontSize: 17, 
        },
      },
    },
    "& .swiper-button-prev": {
      top: 'calc(50% - 37.5px)',
      zIndex: 99,
      background: '#084B83',
      height: 40,
      width: 40,
      left: '12%',
      [theme.breakpoints.down("xl")]: {
        left: '17%',
      },
      [theme.breakpoints.down("1280")]: {
        left: '21%',
      },
      [theme.breakpoints.down("sm")]: {
        height: 25,
        width: 25,
      },
      "&:after":{
        color: "#fff",
        fontSize: 27, 
        [theme.breakpoints.down("sm")]: {
          fontSize: 17, 
        },
      },
    },
    "& .swiper-pagination": {
      zIndex: 33,
      "& .swiper-pagination-bullet": {
        width: 18,
        height: 10,
        borderRadius: 10,
        backgroundColor: "#fff",
        opacity: 2,
        "&:first-child": {
          width: 12,
        },
        "&:last-child": {
          width: 12,
        },
      },
      "& .swiper-pagination-bullet-active": {
        backgroundColor: "#084B83",
      },
    },

    "&:before": {
      position: "absolute",
      content: "''",
      width: "25%",
      height: "90%",
      background:
        "linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
      left: 0,
      zIndex: 50,
      [theme.breakpoints.down("sm")]: {
        content: "",
      },
    },
    "&:after": {
      position: "absolute",
      content: "''",
      width: "25%",
      height: "90%",
      background:
        "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
      right: 0,
      top: 0,
      zIndex: 50,
    },
  },
  listItems: {
    "&.MuiList-root": {
      "& .MuiListItem-root": {
        position: "relative",
        color: theme.palette.secondary.main,
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 5,
        "&:before": {
          position: "absolute",
          content: "''",
          width: 5,
          height: 5,
          backgroundColor: "#000",
          borderRadius: "100%",
          left: 0,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },
      },
    },
  },
  container: {
    "&.MuiContainer-root": {
      padding: 0,
    },
  },
  btn:{
    "&.MuiButtonBase-root.MuiButton-root": {
      padding:" 11.5px 33px",
    },
  }
}));

const Solution = () => {
  const location = useLocation()
  const classes = useStyles();

  const solution = [
    {
      mainHeading: "Digital",
      subHeading: "os",
      text: "Work with Us",
      information:
        "Deliver incredible digital experiences with five-star human-centered digital journeys at a five-bar level of technology and operational efficiency.",
      listHeading: "ghrdfh ",
      subList: [
        {
          img: listImg1,
          heading: "Digital Customer",
          listItem: "asfge",
          listItems: [
            "Omnichannel activation ",
            "Mobile “on-the-go”",
            "Self service",
            "Analytics-driven automation",
          ],
        },
        {
          img: listImg3,
          heading: "Digital Operation",
          listItem: "asfge",
          listItems: [
            "Smart automation",
            "Productivity and efficiency",
            "Connection and collaboration",
            "Insights and analytics",
          ],
        },
        {
          img: listImg2,
          heading: "Digital Growth",
          listItem: "asfge",
          listItems: [
            "Channel optimization",
            "New product launches",
            "New business models",
            "Market expansion",
          ],
        },
      ],
    },
    {
      mainHeading: "Design",
      subHeading: "os",
      text: "Work with Us",
      information:
        "Envision and build successful, usable, and scalable digital experiences, bringing together a data-driven, human-first approach and best design practices and standards. Use design thinking for impact.",
      listHeading: "ghrdfh ",
      subList: [
        {
          img: listImg1,
          heading: "Human-Centered Design",
          listItem: "asfge",
          listItems: [
            "Rapid innovation and prototyping",
            "UX design and development",
            "UX Design center of excellence",
            "Customer research and focus groups",
            "Product market fit",
            "Competitor research and analysis",
            "Inclusive design and development",
          ],
        },
      ],
    },
    {
      mainHeading: "Work",
      subHeading: "os",
      text: "Work with Us",
      information:
        "Reimagine traditional ways of working and evolve for the new world of work. Envision, build, and deliver differentiated customer experiences through brilliant processes and smart technologies.",
      listHeading: "ghrdfh ",
      subList: [
        {
          img: listImg1,
          heading: "Strategy & Planning",
          listItem: "asfge",
          listItems: [
            "Vision and strategy ",
            "Benchmarking and analysis",
            "Organization design",
          ],
        },
        {
          img: listImg3,
          heading: "Digital Transformation",
          listItem: "asfge",
          listItems: [
            "Digital customer experiences",
            "Digital business operations",
            "Digital growth enablement",
          ],
        },
        {
          img: listImg2,
          heading: "Product Centricity",
          listItem: "asfge",
          listItems: [
            "Product practice creation ",
            "Agile transformation ",
            " Value stream implementation ",
            "Capacity planning",
            "  Capability planning",
            "Product workflow implementation",
            "Product team implementation",
            "Scaling agile practices",
          ],
        },
      ],
    },
    {
      mainHeading: "Culture",
      subHeading: "os",
      text: "Work with Us",
      information:
        "Reshape organizational culture, processes, and operations with a human-centered approach to win the hearts and minds of employees. Empower your people to deliver inspiring results.",
      listHeading: "ghrdfh ",
      subList: [
        {
          img: listImg1,
          heading: "Experience Design",
          listItem: "asfge",
          listItems: [
            "Connection and collaboration ",
            "Happiness and fulfillment",
            "Community and engagement",
            "Create moments of magic",
            "Communication and transparency",
          ],
        },
        {
          img: listImg3,
          heading: "Culture Transformation",
          listItem: "asfge",
          listItems: [
            "Outcome focused",
            "Customer obsessed",
            "Data-driven",
            "Self empowered teams",
            "Product centricity",
          ],
        },
        {
          img: listImg2,
          heading: "Performance Excellence",
          listItem: "asfge",
          listItems: [
            "Organization design ",
            "Ways of working design ",
            " Measures and metrics ",
            " Raising the bar",
            "  Trust building",
          ],
        },
        {
          img: listImg1,
          heading: "Talent Development",
          listItem: "asfge",
          listItems: [
            "New talent attraction",
            "Talent growth and retention",
            "Career journey mapping",
            "Onboarding and orientation",
          ],
        },
        {
          img: listImg3,
          heading: "Partnerships",
          listItem: "asfge",
          listItems: [
            "Dynamic partner ecosystem ",
            " Vendor relationship ",
            " Startup collaboration",
          ],
        },
        {
          img: listImg2,
          heading: "Diversity & Inclusion",
          listItem: "asfge",
          listItems: [
            "Developing empathy ",
            "Diversity playbook ",
            " Active inclusion  ",
            "Create belonging",
          ],
        },
      ],
    },
    {
      mainHeading: "Engineering",
      subHeading: "os",
      text: "Work with Us",
      information:
        "Let technology accelerate your future. Leverage best-fit technologies with scalable solutions architected, developed and deployed for you.",
      listHeading: "ghrdfh ",
      subList: [
        {
          img: listImg1,
          heading: "Technology Excellence",
          listItem: "asfge",
          listItems: [
            "Cloud Architecture",
            "Microservices and APIs",
            "DevSecOps and Ci/CD",
            "Ai/ML engineering",
            "Test Automation CoE",
            "Process Automation",
            "Omnichannel platforming",
            "Data OPS",
            "Tools rationalization",
          ],
        },
      ],
    },
  ];
  const handleClickScroll = () => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      handleClickScroll()
    }, 1000)
  }, [])


  return (
    <div className={classes.solutionMain} id="solution-carousel">
      <Container maxWidth="false" className={classes.container}>
        <CustomTypography
          className={classes.solutionHeading}
          align="center"
          variant="h4"
          component="h4"
        >
          Solutions By Expertise
        </CustomTypography>
        
       <CustomSwiper
          loop={true}
          className={classes.solutionSlider}
          centeredSlides={true}
          navigation={{ clickable: true }}
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination, Thumbs, Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1500: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
          }}
          initialSlide={location.state || 0}
        >
          {solution.map((data, i) => (
            <SwiperSlide key={i} className={classes.solutionSlide}>
              <div>
                <CustomGrid
                  container
                  mb={4}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <CustomGrid item>
                    <CustomTypography
                      variant="h2"
                      component="h2"
                      className={classes.mainHeading}
                    >
                      {" "}
                      {data.mainHeading} <sup>{data.subHeading}</sup>
                    </CustomTypography>
                  </CustomGrid>
                  <CustomGrid item>
                    <CustomButton
                      sx={{ display: { xs: "none", md: "block" }, padding: '20px' }}
                      variant="outlined"
                      color="primary"
                      className={classes.btn}
                    >
                      {" "}
                      <a href="mailto:hello@redazure.com">{data.text}</a>
                    </CustomButton>
                  </CustomGrid>
                </CustomGrid>
                <CustomTypography
                  variant="body1"
                  component="p"
                  className={classes.infoText}
                >
                  {data.information}
                </CustomTypography>
                <CustomGrid container spacing={3}>
                  {data?.subList?.map((data, i) => {
                    return (
                      <CustomGrid item xs={12} md={6} lg={4} key={i}>
                        <div className={classes.listMainHeading}>
                          <div className={classes.solutionsIcon}>
                            <img src={data?.img} alt="img" height="58" width="58"/>
                          </div>
                          <div>
                            <CustomTypography variant="h5" component="h5">
                              {data?.heading}
                            </CustomTypography>
                            <List className={classes.listItems}>
                              {data?.listItems?.map((data, i) => (
                                <ListItem key={i} sx={{ listStyleType: "circle" }}>
                                  {data}
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </div>
                      </CustomGrid>
                    );
                  })}
                </CustomGrid>
                <Box align="center">
                  <CustomButton
                    sx={{
                      display: { xs: "block", md: "none" },
                      marginTop: { xs: 5 },
                      padding: '20px' 
                    }}
                    className={classes.btn}
                    variant="outlined"
                    color="primary"
                  >
                    {" "}
                    {data.text}
                  </CustomButton>
                </Box>
              </div>
            </SwiperSlide>
          ))}
        </CustomSwiper>
      
      </Container>
    </div>
  );
};

export default Solution;
