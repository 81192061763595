import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React from "react";

const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButtonBase-root": {
    padding: "8px 20px",
    borderRadius: 40,
    fontSize: 18,
    textTransform: "inherit",
    fontFamily: "Satoshi-Medium",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    "&.MuiButton-outlinedPrimary": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      boxShadow: "none",
    },
  },
}));

export default function CustomButton({ children, ...rest }) {
  return (
    <StyledButton {...rest} disableElevation>
      {children}
    </StyledButton>
  );
}
