import { Box, Container, List, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";
import footerLayer from "../assets/images/footer-layer.webp";
import instagram from "../assets/images/instagramLogo.svg";
import linkedIn from "../assets/images/linkedinLogo.svg";
import CustomButton from "../shared/CustomButton";
import CustomGrid from "../shared/CustomGrid";
import CustomTypography from "../shared/CustomTypography";

const useStyles = makeStyles((theme) => ({
  footerM: {
    paddingTop: [100],
    position: "relative",
    overflow: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      paddingTop: [50],
    },
  },
  fHeading: {
    "&.MuiTypography-root": {
      fontSize: 88,
      marginBottom: 54,
      "& span": {
        position: "relative",
        marginRight: 70,
        "&::after": {
          position: "absolute",
          content: "''",
          height: 18,
          width: 18,
          top: "50%",
          left: -35,
          transform: "translate(-50%,-50%)",
          background: "#000",
          borderRadius: "50%",
          [theme.breakpoints.down("md")]: {
            height: 10,
            width: 10,
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 44,
      },
    },
  },
  mainH: {
    marginBottom: 91,
    [theme.breakpoints.down("md")]: {
      marginBottom: 64,
    },
    "& p": {
      fontSize: 31,
      fontFamily: "Satoshi-Medium",
      marginBottom: 33,
      whiteSpace: "break-spaces",
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
        maxWidth: "80%",
      },
    },
  },
  f1: {
    "& h4": {
      fontSize: 36,
      marginBottom: 26,
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
        marginBottom: 20,
        maxWidth: "80%",
        whiteSpace: "normal",
      },
    },
    "& a": {
      fontSize: 24,
      textDecoration: "none",
      color: theme.palette.secondary.main,
      fontFamily: "Satoshi-Medium",
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },
    },
  },
  // qrCode: {
  //   display: "flex",
  //   alignItems: "center",
  //   [theme.breakpoints.down('md')]:{
  //   marginBottom: 60,
  //   },
  //   "& p": {
  //     fontSize: 16,
  //     marginRight: 24,
  //     fontFamily: "Satoshi-Regular",
  //     [theme.breakpoints.down('md')]:{
  //       fontSize: 14,
  //     },
  //   },
  // },
  qrImg: {
    width: 86,
    height: 86,
    [theme.breakpoints.down("md")]: {
      width: 60,
      height: 60,
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  fList: {
    "&.MuiList-root": {
      padding: "0px !important",
      [theme.breakpoints.down("md")]: {
        marginBottom: 16,
      },
      "& li": {
        marginBottom: 36,
        padding: 0,
        fontFamily: "Satoshi-Medium",
        [theme.breakpoints.down("md")]: {
          marginBottom: 24,
        },
        "& a": {
          textDecoration: "none",
          fontSize: 24,
          fontFamily: "Satoshi-Medium",
          color: theme.palette.secondary.main,
          cursor: "pointer",
          [theme.breakpoints.down("md")]: {
            fontSize: 16,
          },
        },
      },
    },
  },
  sList: {
    "&.MuiList-root": {
      padding: "0px !important",
      display: "flex ",
      marginBottom: 60,
      [theme.breakpoints.down("md")]: {
        marginBottom: 60,
      },
    },
    "& li": {
      width: "max-content",
      padding: 0,
      marginRight: 36,
      [theme.breakpoints.down("lg")]: {
        marginRight: 16,
      },
      [theme.breakpoints.down("md")]: {
        height: 24,
        width: 24,
        marginRight: 20,
      },
      "& a": {
        cursor: "pointer",
      },
    },
  },
  footerLink: {
    "& p": {
      fontFamily: "Satoshi-Medium",
    },
    "& a": {
      color: "#121212",
    },
  },
  footerBg: {
    "& img": {
      position: "absolute",
      width: 500,
      height: 500,
      left: 0,
      bottom: 0,
      filter: "blur(133px)",
      zIndex: -1,
      transform: "rotate(90deg)",
      opacity: "70%",
      [theme.breakpoints.down("md")]: {
        filter: "blur(124px)",
        opacity: "60%",
      },
    },
  },
  border: {
    border: "1px solid #575757",
    [theme.breakpoints.down("md")]: {
      borderColor: "#AE8267",
    },
  },
  btn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 37.5px",
      textTransform: "inherit",
      [theme.breakpoints.down("md")]: {
        padding: " 8.5px 28px",
      },
    },
  },
  footerLast: {
    padding: [[30, 0]],
    borderTop: "1px solid #575757",
    [theme.breakpoints.down("md")]: {
      padding: [[24, 0]],
      borderTop: "1px solid #AE8267",
      "& p": {
        fontSize: 14,
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const menu = [
    {
      list: "What We Do",
      to: "/about",
    },
    {
      list: "Who We Are",
      to: "/who-we-are",
    },
    {
      list: "Vasana",
      to: "/vasana",
    },
    {
      list: "Careers",
      to: "/careers",
    },
    // {
    //   list: "Blogs",
    //   to: "/blog",
    // },
  ];
  const social = [
    {
      sIcon: linkedIn,
      to: "https://www.linkedin.com/company/redazure/",
      target: "_blank",
    },
    {
      sIcon: instagram,
      to: "/",
    },
  ];
  return (
    <>
      <footer className={classes.footerM} id="footer">
        <CustomTypography variant="h2" align="center" color="secondary" className={classes.fHeading}>
          <Parallax translateX={["50px", "-200px"]}>
            <span>Partner with RedAzure</span>
            <span>Partner with RedAzure</span>
            <span>Partner with RedAzure</span>
          </Parallax>
        </CustomTypography>

        <div className={classes.footerBg}>
          <img src={footerLayer} alt="img" height="500" width="500" />
        </div>
        <Container>
          <Box align="center" className={classes.mainH}>
            <CustomTypography mb={3} variant="body1" color="secondary">
              Change the way you work. Build better teams. Shape the future.
            </CustomTypography>
            <a target="_blank" rel="noreferrer" href="mailto:hello@redazure.com">
              <CustomButton className={classes.btn} variant="outlined" color="primary">
                Get in Touch
              </CustomButton>
            </a>
          </Box>
          <CustomGrid container justifyContent="space-between">
            <CustomGrid item xs={12} md={6} className={classes.f1}>
              <Box mb={{ md: 8, xs: 5 }}>
                <Box mb={{ md: 3.25, xs: 2.25 }}>
                  <a target="_blank" rel="noreferrer" href="mailto:hello@redazure.com" underline="none">
                    hello@redazure.com
                  </a>
                </Box>
              </Box>
              {/* <div className={classes.qrCode}>
                <CustomTypography variant="body1" color="secondary">
                  Or scan the QR
                </CustomTypography>
                <Box className={classes.qrImg}>
                  <img src={qrCode} alt="Qr-code" height="86" width="86" />
                </Box>
              </div> */}
            </CustomGrid>
            <CustomGrid item xs={12} md={3}>
              <List className={classes.fList}>
                <CustomGrid container>
                  {menu.map((data, i) => {
                    return (
                      <CustomGrid item xs={6} md={12} key={i}>
                        <ListItem key={i}>
                          <Link to={data.to}> {data.list}</Link>
                        </ListItem>
                      </CustomGrid>
                    );
                  })}
                </CustomGrid>
              </List>
              <List className={classes.sList}>
                {social.map((data, i) => {
                  return (
                    <ListItem key={i}>
                      <a href={data?.to} target={data?.target}>
                        <img src={data.sIcon} width="32" height="32" alt="social-media" />
                      </a>
                    </ListItem>
                  );
                })}
              </List>
            </CustomGrid>
          </CustomGrid>
        </Container>
        <Box className={classes.footerLast}>
          <Container>
            <CustomGrid container justifyContent="space-between">
              <CustomGrid item className={classes.footerLink}>
                <CustomTypography variant="p" color="secondary" component="p">
                  © 2022 - RedAzure
                </CustomTypography>
              </CustomGrid>
            </CustomGrid>
          </Container>
        </Box>
      </footer>
    </>
  );
}
