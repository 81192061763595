import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import CustomTypography from "../../shared/CustomTypography";
import CustomGrid from "../../shared/CustomGrid";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  WhatMakesMain: {
    padding: [[100, 0, 100, 0]],
    [theme.breakpoints.down("md")]: {
      padding: [[70, 0, 70, 0]],
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 42,
      fontFamily: "Satoshi-Bold",
      textAlign: "center",
      marginBottom: 70,
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        marginBottom: 60,
      },
    },
  },
  weAreText: {
    "&.MuiTypography-root": {
      fontSize: 134,
      fontFamily: "Kalam",
      fontWeight: 400,
      color: "#EF767A",
      position: "sticky",
      top: 120,
      [theme.breakpoints.down("md")]: {
        fontSize: 72,
        textAlign: "center",
      },
    },
  },
  detail: {
    "&.MuiTypography-root": {
      fontSize: 24,
      lineHeight: "33px",
      marginBottom: 55,
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
        marginBottom: 35,
      },
      "&:first-letter": {
        fontFamily: "Satoshi-Bold",
        fontSize: 42,
        color: theme.palette.primary.main,
        [theme.breakpoints.down("md")]: {
          fontSize: 36,
        },
      },
      "&:last-child": {
        marginBottom: 0,
      },
      "& span": {
        fontFamily: 'Satoshi-Medium',
      },
    },
  },
}));



const WhatMakes = () => {
  const location = useLocation()
  const classes = useStyles();
  const info = [
    {
      information:
        "<span>Humble and Authentic:</span> We are transparent, clear, and direct. But we are never bossy or cocky.",
    },
    {
      information:
        "<span>Understanding and Relatable:</span> We recognize the humanity in us and others. We encourage open, honest, two-way conversations. We value empathy and respect. We are thoughtful, respectful, purposeful, and collaborative.",
    },
    {
      information:
        "<span>Maverick Pioneers:</span>  We think outside the proverbial box. We know that we must disrupt the status quo to change the world. We are unafraid to take a stand. We have opinions that are driven by strong convictions and grounded in facts.",
    },
    {
      information:
        "<span>Approachable and Friendly: </span> We are warm and personable. We want to make people feel welcome. We are upbeat and friendly. We make people more productive, happy, and energized. We take what we do seriously but do not take ourselves seriously.",
    },
    {
      information:
        "<span> Nurturing and Inclusive:</span> We work around the needs of people. We inhabit an environment dedicated to seeing everyone belong and thrive. We are people- and individual-first and champion inclusivity.",
    },
  ];
  const handleClickScroll = () => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      handleClickScroll()
    }, 1000)
  }, [])
  return (
    <div className={classes.WhatMakesMain} id="we-human">
      <Container>
        <CustomTypography
          className={classes.heading}
          component="h3"
          variant="h3"
        >
          What Makes RedAzure Different
        </CustomTypography>
        <CustomGrid container spacing={3}>
          <CustomGrid item md={6} xs={12} mb={{ sm: 8, xs: 4 }}>
            <CustomTypography
              variant="h2"
              component="h2"
              className={classes.weAreText}
            >
              {" "}
              We are
            </CustomTypography>
          </CustomGrid>
          <CustomGrid item md={6} xs={12}>
            {info.map((data, i) => {
              return (
                <CustomTypography
                  key={i}
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: data.information }}
                  className={classes.detail}
                  component="p"
                ></CustomTypography>
              );
            })}
          </CustomGrid>
        </CustomGrid>
      </Container>
    </div>
  );
};

export default WhatMakes;
