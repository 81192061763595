import arrow from "../assets/images/arrow-up.svg";

export const caseStudies = [
    {
      id: 1,
      iframe: "https://www.youtube.com/embed/mGJkhuRlvTo",
      infoHeading:
        "Accelerating Innovation and Digital Transformation in Your Organization",
      infoTag: "Case Study",
      blogContent: [
        '"The people who are doing the work, actually know the work better than anyone and already know what needs to be done. They have already figured out the why and what, but have never been empowered enough or have never been provided with the right tools or processes to be able to deliver on this."',
        "Our founder Veresh Sita speaks to Howard Tiersky, founder and CEO of FROM, The Digital Transformation Agency, about how he helps unlock an organization’s potential and accelerates transformation. He expands on how he approaches transformations with a human-first outlook and focuses on the how and the who."
      ],
      arrow: arrow,
      link: "/blogs",
    },
    {
      id: 2,
      iframe: "https://www.youtube.com/embed/HNUt8gmCfYc",
      infoHeading:
        "The 60-Second Airline: Powering the Growth of Alaska Airline",
      infoTag: "Interview",
      blogContent: [
        "We travel back in time when Veresh Sita, our founder, gave the keynote at the ChefConf 2016 where he spoke about running an efficient organization and fostering technology innovation with agility and at velocity.",
      ],
      arrow: arrow,
      link: "/blogs",
    },
  ];