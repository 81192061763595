import { makeStyles } from "@mui/styles";
import React from "react";
import { Autoplay, Navigation, Thumbs } from "swiper";
import { SwiperSlide } from "swiper/react";
import Brand1 from "../../assets/images/brand-1.svg";
import Brand10 from "../../assets/images/brand-10.svg";
import Brand2 from "../../assets/images/brand-2.svg";
import Brand3 from "../../assets/images/brand-3.svg";
import Brand5 from "../../assets/images/brand-5.svg";
import Brand6 from "../../assets/images/brand-6.svg";
import Brand7 from "../../assets/images/brand-7.svg";
import Brand4 from "../../assets/images/campgemini.svg";
import CustomSwiper from "../../shared/CustomSwiper";
import Brand12 from "../../assets/images/Frame-8.svg";

const useStyles = makeStyles((theme) => ({
  BrandM: {
    padding: [[120, 0, 60, 0]],
    [theme.breakpoints.down("md")]: {
      padding: [[70, 0]],
    },
    [theme.breakpoints.down('sm')]:{
      padding:[[20,0,30,0]]
    }
  },
  swiperS: {
    padding: [[30, 0]],
    [theme.breakpoints.down('sm')]:{
      padding: [[20, 0]],
    },
    "& .swiper-wrapper": {
      alignItems: 'center',
    }
  },
  greyScale: {
    "& img": {
      filter: "grayscale(100%)",
    },
  },
  slides: {
    textAlign: "center",
    height: "100%",
    width: "100%",
    "& img": {
      maxWidth: "100%",
    },
  },
}));

const BrandLogo = () => {
  const classes = useStyles();
  const Brands = [
    {
      img: Brand1,
    },
    {
      img: Brand2,
    },
    {
      img: Brand10,
    },
    {
      img: Brand3,
      className: classes.greyScale,
    },
    {
      img: Brand4,
      className:classes.greyScale
    },
  ];
  const Brands2 = [
    {
      img: Brand5,
    },
    {
      img: Brand12,
      className: classes.greyScale,
    },
    {
      img: Brand6,
    },
    {
      img: Brand7,
    },
  ];
  return (
    <div className={classes.BrandM}>
      <CustomSwiper
        
        loop="true"
        className={classes.swiperS}
        modules={[Navigation, Thumbs, Autoplay]}
        speed={5000}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        breakpoints={{
          0: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          576: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
      >
        {Brands.map((data, i) => (
          <SwiperSlide key={i}>
            <div className={`${classes.slides} ${data.className}`}>
              <img src={data.img} alt="brand-logo" height="50" width="244" />
            </div>
          </SwiperSlide>
        ))}
      </CustomSwiper>

      <CustomSwiper
        loop="true"
        className={classes.swiperS}
        modules={[Navigation, Thumbs, Autoplay]}
        speed={5000}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          reverseDirection: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          576: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
      >
        {Brands2.map((data, i) => (
          <SwiperSlide key={i}>
            <div className={`${classes.slides} ${data.className}`}>
              <img src={data.img} alt="brand-logo" height="50" width="244" />
            </div>
          </SwiperSlide>
        ))}
      </CustomSwiper>
    </div>
  );
};

export default BrandLogo;
