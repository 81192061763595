import { makeStyles } from "@mui/styles";
import { Box, Container } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import wImg from "../../assets/images/shape1.svg";
import work1 from "../../assets/images/work-1.webp";
import work2 from "../../assets/images/work-2.webp";
import work3 from "../../assets/images/work-3.webp";
import work4 from "../../assets/images/work-4.webp";
import work5 from "../../assets/images/work-5.webp";
import work6 from "../../assets/images/work-6.webp";
import workPattern1 from "../../assets/images/work-pattern-1.png";
import workPattern2 from "../../assets/images/work-pattern-2.png";
import workPattern3 from "../../assets/images/work-pattern-3.png";
import CustomButton from "../../shared/CustomButton";
import CustomTypography from "../../shared/CustomTypography";

const useStyles = makeStyles((theme) => ({
  workWithM: {
    padding: [[250, 0]],
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("1450")]: {
      padding: [[250, 0]],
    },
    [theme.breakpoints.down("md")]: {
      padding: [[250, 0, 300, 0]],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[300, 0, 350, 0]],

    },
  },
  wHeading: {
    textAlign: "center",
    position: "relative",
    animation: "up 2s ease-in-out infinite",
    marginBottom: 44,
    [theme.breakpoints.down('md')]:{
      marginBottom: 25,
    },
    "& img":{
      [theme.breakpoints.down('md')]:{
        width: 166,
        height: 166,
      }
    },
    "& p": {
      position: "absolute",
      transform: "translate(-50%,-50%)",
      top: "50%",
      left: "50%",
      color: theme.palette.natural.main,
      fontSize: 24,
      fontFamily: "Satoshi-Medium",
      [theme.breakpoints.down('md')]:{
        fontSize: '15.4px'
      }
      
    },
  },
  subH: {
    "&.MuiTypography-root": {
      marginBottom: 48,
      fontSize: 88,
      textAlign: "center",
      color: theme.palette.secondary.main,

      [theme.breakpoints.down("1281")]: {
        fontSize: 60,
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: 48,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 48,
        "& br": {
          display: 'none'
        }
      },
    },
  },
  workImg: {
    position: "absolute",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 24,
    },
  },
  workI1: {
    width: 260,
    height: 160,
    left: "26%",
    top: "15%",
    animation: "up 2s ease-in-out infinite",
    animationDelay: "1.2s",
    [theme.breakpoints.down("1450")]: {
      left: "20%",
      top: '15%'
    },
    [theme.breakpoints.down("1340")]: {
      left: "20%",
      top: '12%'
    },
    [theme.breakpoints.down("lg")]: {
      width: 200,
      height: 130,
      left: "24%",
      top: '6%'
    },
    [theme.breakpoints.down("md")]: {
      left: "-4%",
      top: "6%",
    },
    [theme.breakpoints.down("481")]: {
      left: "-8%",
    },
    [theme.breakpoints.down("426")]: {
      width: 180,
      height: 120,
      left: "-4%",
      top: '10%'
    },
  },
  workI2: {
    width: 245,
    height: 330,
    right: "10%",
    top: "10%",
    animation: "up 2s ease-in-out infinite",
    animationDelay: "0.8s",

    [theme.breakpoints.down("1450")]: {
      right: "10%",
      top: '12%'
    },
    [theme.breakpoints.down("lg")]: {
      width: 200,
      height: 280,
    },
    [theme.breakpoints.down("md")]: {
      width: 200,
      height: 280,
      right: "-7%",
      top: "3%",
    },
    [theme.breakpoints.down("481")]: {
      width: 136,
      height: 181,
      right: "-7%",
      top: "9%",
    },
    [theme.breakpoints.down("426")]: {
      right: "-12%",
    },
    [theme.breakpoints.down("375")]: {
      right: "-15%",
    },
  },
  workI3: {
    width: 245,
    height: 330,
    top: "28%",
    left: "-21px",
    animation: "up 2s ease-in-out infinite",
    animationDelay: "1s",

    [theme.breakpoints.down("lg")]: {
      width: 200,
      height: 280,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  workI4: {
    width: 362,
    height: 230,
    top: "49%",
    right: "-4%",
    animation: "up 2s ease-in-out infinite",
    animationDelay: "0.8s",
    [theme.breakpoints.down("lg")]: {
      width: 300,
      height: 190,
      right: "-10%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  workI5: {
    width: 245,
    height: 330,
    bottom: "6%",
    left: "6%",
    animation: "up 2s ease-in-out infinite",
    animationDelay: "1.3s",

    [theme.breakpoints.down("lg")]: {
      width: 190,
      height: 280,
    },
    [theme.breakpoints.down("1450")]: {
      left: "6%",
      bottom: "5%",
    },
    [theme.breakpoints.down("md")]: {
      width: 200,
      height: 280,
      bottom: "5%",
    },
    [theme.breakpoints.down("481")]: {
      width: 140,
      height: 187,
      bottom: "8%",

    },
    [theme.breakpoints.down("426")]: {
      width: 140,
      height: 187,
    },
    [theme.breakpoints.down("375")]: {
      left: "-4%",
    },
  },
  workI6: {
    width: 324,
    height: 210,
    right: "8%",
    bottom: "3%",
    animation: "up 2s ease-in-out infinite",
    animationDelay: "1.8s",
    [theme.breakpoints.down("lg")]: {
      width: 280,
      height: 180,
      right: "5%",
      bottom: "3%",
    },
    [theme.breakpoints.down("md")]: {
      right: "-17%",
      bottom: "10%",
    },
    [theme.breakpoints.down("481")]: {
      width: 210,
      height: 134,
      right: "-16%",
      bottom: "10%",
    },
    [theme.breakpoints.down("426")]: {
      right: "-18%",
      bottom: "13%",
    },
    [theme.breakpoints.down("376")]: {
      right: "-20%",
    },
  },
  wPattern1: {
    top: "9%",
    left: "39%",
   
  },
  wPattern2: {
    bottom: "3%",
    left: "16%",
  },
  wPattern3: {
    right: "6%",
    bottom: "17%",
  },
  subPattern1: {
    position: "absolute",
    top: "-31%",
    right: "-17%",
    "& img":{
      [theme.breakpoints.down('450')]:{
        width: '68px'
      }
    }
  },
  subPattern2: {
    position: "absolute",
    bottom: "-15%",
    right: "-17%",
    "& img":{
      [theme.breakpoints.down('450')]:{
        width: '64px'
      }
    }
  
  },
  subPattern3: {
    position: "absolute",
    top: "-20%",
    right: "-12%",
  },
  btn:{
    "&.MuiButtonBase-root.MuiButton-root": {
      padding:" 11.5px 37.5px",
      [theme.breakpoints.down("md")]: {
        padding:" 8.5px 28px",
      },
    },
  }
}));

const WorkWithUs = () => {
  const classes = useStyles();
  const imageList = [
    {
      img: work1,
      className: classes.workI1,
      subImage: { img: workPattern1, className: classes.subPattern1 },
    },
    {
      img: work2,
      className: classes.workI2,
    },
    {
      img: work3,
      className: classes.workI3,
    },
    {
      img: work4,
      className: classes.workI4,
    },
    {
      img: work5,
      className: classes.workI5,
      subImage: { img: workPattern2, className: classes.subPattern2 },
    },
    {
      img: work6,
      className: classes.workI6,
      subImage: { img: workPattern3, className: classes.subPattern3 },
    },
  ];
  return (
    <div className={classes.workWithM}>
      {imageList.map((data, i) => {
        return (
          <div className={`${classes.workImg} ${data.className}`} key={i}>
            <img src={data.img} alt="img" />
            {data.subImage && (
              <div className={data.subImage.className}>
                <img src={data.subImage.img} alt="img" />
              </div>
            )}
          </div>
        );
      })}

      <Container>
        <div className={classes.wHeading}>
          <img src={wImg} width="256" height="258" alt="shape" />
          <CustomTypography variant="body1" component="p">
            Work With Us
          </CustomTypography>
        </div>
        <CustomTypography variant="h2" className={classes.subH} component="h2">
          Working With the <br></br> Best to Achieve <br></br> the Amazing
        </CustomTypography>
        <Box align="center">
          <a target="_blank" rel="noreferrer" href="mailto:hello@redazure.com">
            <CustomButton className={classes.btn} sx={{display:{xs:'none',md:'block'}}} variant="outlined" color="primary">
            Learn More 
            </CustomButton>
          </a>
          <CustomButton className={classes.btn} sx={{display:{xs:'block',md:'none'},padding:[[12,40]]}} variant="outlined" color="primary">
            <Link to="/careers">Know more</Link>
          </CustomButton>
        </Box>
      </Container>
    </div>
  );
};

export default WorkWithUs;
