import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import CustomTypography from "../../shared/CustomTypography";
import CustomGrid from "../../shared/CustomGrid";
import team1 from "../../assets/images/team-img-1.png";
import team2 from "../../assets/images/team-img-2.png";
import colorLayer from "../../assets/images/footer-layer.webp";

const useStyles = makeStyles((theme) => ({
  InfoSection: {
    padding: [[85, 0, 150, 0]],
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: [[70, 0, 125, 0]],
    },
  },
  headingInformation: {
    "&.MuiTypography-root": {
      fontSize: 32,
      paddingRight: 150,
      lineHeight: 1.54,
      marginBottom: 100,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 32,
        paddingRight: 100,
        marginBottom: 90,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
      },
    },
  },
  teamMain: {
    color: theme.palette.secondary.main,
    "& h4": {
      fontSize: 32,
      fontFamily: "Satoshi-Bold",
      marginBottom: 35,
      textAlign: "center",
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 28,
        marginBottom: 30,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
        marginBottom: 25,
        padding: [[0, 20]],
      },
    },
    "& p": {
      fontSize: 20,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  teamImage: {
    height: 400,
    marginBottom: 40,
    [theme.breakpoints.down("md")]: {
      marginBottom: 30,
    },
    [theme.breakpoints.down("sm")]: {
      height: 230,
      marginBottom: 25,
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 24,
    },
  },
  colorImage: {
    position: "absolute",
    filter: "blur(140px)",
    zIndex: -1,
    transform: "rotate(80deg)",
    top: "-10%",
    maxWidth: "100%",
    left: "2%",
    opacity: "80%",
    [theme.breakpoints.down("md")]: {
      left: "-10%",
    },
  },
}));

const InfoSection = () => {
  const classes = useStyles();
  const twoBox = [
    {
      heading: "Advisory, Coaching, and Mentoring",
      image: team1,
      information: "Transform business leaders and teams with coaching on active business projects, using our proprietary products.",
    },
    {
      heading: "Design, Building, and Delivery",
      image: team2,
      information: "Enable agility, velocity, growth, and innovation with scalable strategies and modern ways of working.",
    },
  ];
  return (
    <div className={classes.InfoSection}>
      <img src={colorLayer} className={classes.colorImage} alt="img" height="460" width="460" />
      <Container>
        <CustomTypography variant="h4" className={classes.headingInformation} component="h4">
          Renew and reshape your organization with our services and capabilities that take you into the future. We are creators, co-creators,
          collaborators, and co-conspirators enabling your company through:
        </CustomTypography>
        <CustomGrid container spacing={{ lg: 11, xs: 8 }}>
          {twoBox.map((data, i) => {
            return (
              <CustomGrid item key={i} xs={12} md={6} className={classes.teamMain}>
                <CustomTypography variant="h4" component="h4">
                  {data.heading}
                </CustomTypography>
                <div className={classes.teamImage}>
                  <img src={data.image} alt="img" height="572" width="400" />
                </div>
                <CustomTypography variant="body1" component="p">
                  {data.information}
                </CustomTypography>
              </CustomGrid>
            );
          })}
        </CustomGrid>
      </Container>
    </div>
  );
};

export default InfoSection;
