import Banner from "./banner";
import WeAre from "./WeAre";
import Productize from "./Productize";
import Teams from "./Teams";
import WorkWithUs from "./WorkWithUs";
import BrandLogo from "./BrandLogo";
import CaseStudies from "./CaseStudies";

function HomePageComponent() {
  return (
    <>
      <Banner />
      <WeAre />
      <Productize />
      <Teams />
      <WorkWithUs />
      <BrandLogo />
      <CaseStudies />
    </>
  );
}

export default HomePageComponent;
