import React from "react";
import Banner from "./banner";
import OurPurpose from "./OurPurpose";
import RedAzureStory from "./RedAzureStory";

const WhoWeAreComponent = () => {
  return (
    <div>
      <Banner />
      <OurPurpose />
      <RedAzureStory />
    </div>
  );
};

export default WhoWeAreComponent;
