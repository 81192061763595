import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import React from "react";
import CustomTypography from "../../shared/CustomTypography";
import bgImg from "../../assets/images/vasana-bg.png";
import { Box } from "@mui/material";
import CustomButton from "../../shared/CustomButton";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bannerWrap: {
    padding: "100px 0",
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.main,

    [theme.breakpoints.down("md")]: {
      padding: [[90, 0, 100]],
    },
    [theme.breakpoints.down("480")]: {
      overflow:'hidden',
    },
  },
  bannerText: {
    "& h3": {
      color: theme.palette.secondary.main,
      fontSize: 42,
      margin: [[0, 0, 30, 0]],
      fontFamily: "Satoshi-Bold",
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
      },
    },
    "& p": {
      fontSize: 20,
      marginBottom: 40,
      maxWidth: 819,
      lineHeight: "27px",
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        marginBottom: 20,
      },
    },
    "& h6": {
      fontFamily: "Satoshi-Medium",
      marginBottom: 24,
      lineHeight: 1.4,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  },
  bgImg: {
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      right: "-20px",
      top: "-20px",
    },
  },
  btn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 33.5px",
      [theme.breakpoints.down("md")]: {
        padding: " 8.5px 25px",
      },
    },
  },
}));

export default function Banner() {
  const classes = useStyles();
  return (
    <div className={classes.bannerWrap}>
      <img src={bgImg} className={classes.bgImg} alt="img" />
      <Container>
        <Box mt={{ md: 12.2, xs: 10 }}>
          <div className={classes.bannerText}>
            <CustomTypography variant="h6" component="h6">
              Vasana For Teams{" "}
            </CustomTypography>
            <CustomTypography variant="h3" component="h3">
              Better Teams equal Exceptional Performance
            </CustomTypography>
            <CustomTypography variant="body1" component="p">
              Our science-backed analytics powered by the Vasana Behavioral
              Genome helps create working environments where relationships
              thrive. Our proprietary tools use behavioral science and
              neuroscience to reduce friction between people, increase
              productivity, and accelerate individual and team performance.{" "}
            </CustomTypography>
            <a href="#Vasana-Teams">
            <CustomButton
              className={classes.btn}
              variant="outlined"
              color="primary"
            >
              Transform with Vasana 
            </CustomButton>
            </a>
          </div>
        </Box>
      </Container>
    </div>
  );
}
