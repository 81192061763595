import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container, Stack } from "@mui/material";
import CustomTypography from "../../shared/CustomTypography";
import CustomButton from "../../shared/CustomButton";
import speach from "../../assets/images/speach-img.png";
import colorLayer from "../../assets/images/footer-layer.webp";
import CustomGrid from "../../shared/CustomGrid";

const useStyles = makeStyles((theme) => ({
  heading: {
    "&.MuiTypography-h3": {
      color: theme.palette.secondary.main,
      fontSize: 42,
      marginBottom: 30,
      fontFamily: "Satoshi-Bold",
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        marginBottom: 20,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
        marginBottom: 0,
      },
    },
  },
  bannerText: {
    "& p": {
      fontSize: 18,
      marginBottom: 30,
      lineHeight: "24px",
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        marginBottom: 20,
      },
    },
    "& h6": {
      fontFamily: "Satoshi-Medium",
      marginBottom: 24,
      lineHeight: 1.4,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  },
  btn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 33.5px",
      [theme.breakpoints.down("md")]: {
        padding: " 8.5px 25px",
      },
    },
  },
  imgWrapper: {
    borderRadius: 20,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& img": {
      borderRadius: 20,
      minHeight: 386,
      objectFit: "cover",
    },
  },
  colorImage: {
    position: "absolute",
    filter: "blur(140px)",
    zIndex: -1,
    transform: "rotate(80deg)",
    bottom: "0%",
    maxWidth: "480px",
    width: "100%",
    height: "480px",
    left: "-10%",
    opacity: "30%",
    [theme.breakpoints.down("md")]: {
      left: "-10%",
    },
  },
  bannerWrap: {
    padding: "100px 0 0",
    [theme.breakpoints.down("xl")]: {
      padding: "90px 0 0",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "70px 0 0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "70px 0 0",
    },
  },
}));
const AboutSection = () => {
  const classes = useStyles();
  return (
    <Box position="relative" id="market-force" className={classes.bannerWrap}>
      <img src={colorLayer} alt="colorLayer" className={classes.colorImage} />
      <Container>
        <CustomTypography variant="h3" component="h3" fontFamily="Satoshi-Bold" textAlign="center" className={classes.heading}>
          What is Market Force?
        </CustomTypography>
        <CustomGrid
          container
          mt={{ md: 3, xs: 0 }}
          spacing={{ xs: 3, md: 4, lg: 5 }}
          direction={{ md: "row", xs: "column-reverse" }}
          justifyContent="center"
          alignItems="center"
        >
          <CustomGrid item md={6} display="flex" justifyContent="center" style={{ paddingTop: "0" }}>
            <Stack className={classes.imgWrapper}>
              <img src={speach} alt="speach" />
            </Stack>
          </CustomGrid>
          <CustomGrid item md={6} style={{ padding: "0" }}>
            <Box className={classes.bannerText} py="28px" pl={{ lg: "68px", md: "40px", xs: "24px" }} textAlign={{ md: "start", xs: "center" }}>
              <CustomTypography variant="body1" component="p">
                With over 40 years at the forefront of Learning & Development, we specialize in transforming teams and leaders across various
                industries. Our proprietary, easy-to-learn methodology blends intuitive business practices with cutting-edge neuroscience, fostering
                remarkable improvements in team dynamics, leadership, negotiation, strategic development, and more.
              </CustomTypography>
              <CustomTypography variant="body1" component="p">
                We are a human-focused, industry-agnostic company with an international footprint and proven results across various industries,
                company sizes, and cultures.
              </CustomTypography>
              <a href="https://www.vasana.io/" rel="noreferrer" target="_blank">
                <CustomButton className={classes.btn} variant="outlined" color="primary">
                  Work with Us
                </CustomButton>
              </a>
            </Box>
          </CustomGrid>
        </CustomGrid>
      </Container>
    </Box>
  );
};

export default AboutSection;
