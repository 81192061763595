import React from "react";
import Banner from "./banner";
import WhatMakes from "./WhatMakes";
import OpenRoles from "./OpenRoles";

const index = () => {
  return (
    <div>
      <Banner />
      <WhatMakes />
      <OpenRoles />
    </div>
  );
};

export default index;
