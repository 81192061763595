import React, { useEffect } from "react";
import { Box, Container, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomTypography from "../../shared/CustomTypography";
import CustomSwiper from "../../shared/CustomSwiper";

import conference from "../../assets/images/conference.png";
import livewebinar from "../../assets/images/livewebinar.png";
import workshops from "../../assets/images/workShop.png";
import presentation from "../../assets/images/presentation.png";
import together from "../../assets/images/togather.png";
import speech from "../../assets/images/speech.png";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { SwiperSlide } from "swiper/react";
import CustomButton from "../../shared/CustomButton";

const useStyles = makeStyles((theme) => ({
  bannerWrap: {
    padding: "100px 0",
    position: "relative",
    color: theme.palette.secondary.main,

    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      padding: "90px 0 100px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "70px 0 80px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "60px 0 30px",
    },
    [theme.breakpoints.down("480")]: {
      overflow: "hidden",
    },
  },
  bannerText: {
    textAlign: "center",
    "& h3": {
      color: theme.palette.secondary.main,
      fontSize: 42,
      marginBottom: 30,
      fontFamily: "Satoshi-Bold",
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        marginBottom: 20,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
      },
    },
    "& p": {
      fontSize: 18,
      margin: "0 auto 0px auto",
      maxWidth: 1020,
      lineHeight: "24px",
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
    "& h6": {
      fontFamily: "Satoshi-Medium",
      marginBottom: 24,
      lineHeight: 1.4,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  },
  btn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 33.5px",
      [theme.breakpoints.down("md")]: {
        padding: " 8.5px 25px",
      },
    },
  },

  approchSlider: {
    paddingLeft: 16,
    "& .swiper-button-next,.swiper-button-prev": {
      background: theme.palette.natural.main,
      width: "38px",
      height: "38px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      "&::after": {
        fontSize: 18,
        lineHeight: "0px",
        color: theme.palette.secondary.white,
      },
      "&.swiper-button-disabled": {
        opacity: 0,
      },
      "&.swiper-button-prev": {
        left: 26,
      },
    },
  },
  approchSlideImage: {
    borderRadius: "15.6px",
    width: "100%",
    "&::selection": {
      display: "none",
    },
  },
}));

const Approch = () => {
  const classes = useStyles();
  const logo = [
    {
      logo: conference,
    },
    {
      logo: livewebinar,
    },
    {
      logo: presentation,
    },
    {
      logo: workshops,
    },
    {
      logo: speech,
    },
    {
      logo: together,
    },
  ];
  SwiperCore.use([Autoplay, Navigation]);

  return (
    <Box className={classes.bannerWrap}>
      <Container>
        <Box className={classes.bannerText} mb={{ xl: 7.5, xs: 5 }}>
          <CustomTypography variant="h3" component="h3">
            A Transformative Approach
          </CustomTypography>
          <CustomTypography variant="body1" component="p">
            The Market Force methodology has 45+ frameworks designed to reduce friction, improve collaboration, and accelerate performance, with
            training delivered through multiple methods, including in-person training, workshops, live webinars, online learning, internal trainers,
            and coaching.
          </CustomTypography>
        </Box>
      </Container>
      <Box position="relative">
        <CustomSwiper
          className={classes.approchSlider}
          modules={[Navigation]}
          spaceBetween={30}
          loop={true}
          navigation={true}
          initialSlide={0}
          breakpoints={{
            0: {
              slidesPerView: 1.2,
              spaceBetween: 16,
            },
            400: {
              slidesPerView: 1.5,
            },
            524: {
              slidesPerView: 2,
            },
            767: {
              slidesPerView: 2.2,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 4.2,
            },
          }}
        >
          {logo.map((data, i) => (
            <SwiperSlide key={i}>
              <Stack justifyContent="center" alignItems="center" height="100%">
                <img src={data?.logo} alt="" className={classes.approchSlideImage} />
              </Stack>
            </SwiperSlide>
          ))}
        </CustomSwiper>
        <Box display={{ md: "none", xs: "flex" }} justifyContent={"center"} mt={2.75}>
          <a href="#Vasana-Teams">
            <CustomButton className={classes.btn} variant="outlined" color="primary">
              Work with Us
            </CustomButton>
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default Approch;
