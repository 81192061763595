import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import teamImg from "../../assets/images/our-purpose-img.png";
import teamImg2 from "../../assets/images/team-img.webp";
import CustomButton from "../../shared/CustomButton";
import CustomGrid from "../../shared/CustomGrid";
import CustomTypography from "../../shared/CustomTypography";

const useStyles = makeStyles((theme) => ({
  teamsSection: {
    padding: [[130, 0, 125, 0]],
    [theme.breakpoints.down("md")]: {
      padding: [[115, 0, 90, 0]],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[80, 0, 70, 0]],
    },
  },
  teamHeadingM: {
    marginBottom: 100,
    [theme.breakpoints.down("md")]: {
      marginBottom: 80,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
      maxWidth: '80%'
    },
  },
  teamsHeading1: {
    "&.MuiTypography-root": {
      fontSize: 31,
      fontFamily: "Satoshi-Bold",
      marginBottom: 30,
      color: theme.palette.secondary.main,
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
        marginBottom: 25,
      },
    },
  },
  teamsHeading2: {
    "&.MuiTypography-root": {
      fontSize: 72,
      lineHeight: 1.3,
      color: theme.palette.secondary.main,

      [theme.breakpoints.down("md")]: {
        fontSize: 48,
      },
    },
  },
  teamImg: {
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 24,
    },
  },
  teamInfo: {
    "&.MuiTypography-root": {
      fontSize: 24,
      marginBottom: 30,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },
    },
  },
  infoMain: {
    [theme.breakpoints.down("md")]: {
    padding: [[0, 0, 0, 150]],
    },
    [theme.breakpoints.down("sm")]: {
    padding: [[0, 0, 0, 120]],
    },
    [theme.breakpoints.down("440")]: {
    padding: [[0, 0, 0, 78]],
    },
    [theme.breakpoints.down("360")]: {
    padding: [[0, 0, 0, 50]],
    },
  },
  teamInfoM: {
    display: "flex",
    alignItems: "flex-end",
  },
  img1: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  img2: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  btn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 27.5px",
      [theme.breakpoints.down("md")]: {
        padding: " 8.5px 22px",
      },
    },
  },
}));

const Teams = () => {
  const classes = useStyles();
  return (
    <div className={classes.teamsSection}>
      <Container>
        <div className={classes.teamHeadingM}>
          <CustomTypography variant="h4" className={classes.teamsHeading1}>
            Our Purpose
          </CustomTypography>
          <CustomTypography variant="h2" className={classes.teamsHeading2}>
            We exist to help people thrive.
          </CustomTypography>
        </div>
        <div>
          <CustomGrid container spacing={{ xs: 5, md: 10 }}>
            <CustomGrid item xs={12} md={6}>
              <div className={classes.teamImg}>
                <img src={teamImg} className={classes.img1} alt="team" />
                <img src={teamImg2} className={classes.img2} alt="team" />
              </div>
            </CustomGrid>
            <CustomGrid item xs={12} md={6} className={classes.teamInfoM}>
              <div className={classes.infoMain}>
                <CustomTypography variant="body1" className={classes.teamInfo}>
                  We want to expand human potential, improve the business of
                  businesses, and build a brighter future. We are a human-first
                  company driven by our mission to create a more inclusive and
                  accepting world in our company, the organizations we work
                  with, and our communities.
                </CustomTypography>
                <a href="/careers#we-human">
                  <CustomButton 
                    className={classes.btn}
                    variant="outlined"
                    color="primary"
                  >
                  We are Human
                  </CustomButton>
                </a>
              </div>
            </CustomGrid>
          </CustomGrid>
        </div>
      </Container>
    </div>
  );
};

export default Teams;
