import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import React from "react";
import CustomTypography from "../../shared/CustomTypography";
import bgImg from "../../assets/images/vasana-bg.png";
import { Box, Stack } from "@mui/material";
import CustomButton from "../../shared/CustomButton";
import bannerImg from "../../assets/images/marketforce-banner.png";
import CustomGrid from "../../shared/CustomGrid";

const useStyles = makeStyles((theme) => ({
  bannerWrap: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.main,
    padding: "100px 0 0",
    [theme.breakpoints.down("xl")]: {
      padding: "90px 0 0",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "70px 0 0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "70px 0 0",
    },
    [theme.breakpoints.down("480")]: {
      overflow: "hidden",
    },
  },
  bannerText: {
    "& h3": {
      color: theme.palette.secondary.main,
      fontSize: 42,
      marginBottom: 30,
      fontFamily: "Satoshi-Bold",
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        marginBottomL: 20,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
      },
    },
    "& p": {
      fontSize: 20,
      marginBottom: 40,
      maxWidth: 879,
      lineHeight: "27px",
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        marginBottom: 20,
      },
    },
    "& h6": {
      fontFamily: "Satoshi-Medium",
      marginBottom: 24,
      lineHeight: 1.4,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  },
  bgImg: {
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      right: "-20px",
      top: "-20px",
    },
  },
  btn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 33.5px",
      [theme.breakpoints.down("md")]: {
        padding: " 8.5px 25px",
      },
    },
  },
  imgWrapper: {
    borderRadius: 20,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& img": {
      borderRadius: 20,
      maxHeight: 520,
    },
  },
}));

export default function Banner() {
  const classes = useStyles();
  return (
    <div className={classes.bannerWrap}>
      <img src={bgImg} className={classes.bgImg} alt="img" />
      <Container>
        <CustomGrid container mt={{ md: 8, sm: 6, xs: 4 }} spacing={{ xs: 3, md: 4, lg: 5 }} justifyContent="center" alignItems="center">
          <CustomGrid item md={7} style={{ paddingTop: "0" }}>
            <Box className={classes.bannerText} textAlign={{ md: "start", xs: "center" }}>
              <CustomTypography variant="h3" component="h3">
                Harness Your Potential, Accelerate Your Business
              </CustomTypography>
              <CustomTypography variant="body1" component="p">
                Discover Market Force, the revolutionary methodology that transforms businesses by elevating leadership, teamwork, and relationships.
              </CustomTypography>
              <a href="#market-force" className="learnMore">
                <CustomButton className={classes.btn} variant="outlined" color="primary">
                  Learn More
                </CustomButton>
              </a>
            </Box>
          </CustomGrid>
          <CustomGrid item md={5} display="flex" justifyContent="center">
            <Stack className={classes.imgWrapper}>
              <img src={bannerImg} alt="banner" />
            </Stack>
          </CustomGrid>
        </CustomGrid>
      </Container>
    </div>
  );
}
