import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { ReactComponent as Logo } from "../assets/images/logo-dark.svg";

const useStyles = makeStyles(() => ({
  loader: {
    position: "fixed",
    width: "100%",
    height: "100%",
    background: "#fff",
    top: 0,
    zIndex: 1500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& span":{
      "&.MuiLinearProgress-root":{
        backgroundColor: "#efcbcd",
      },
      "&.MuiLinearProgress-bar":{
        backgroundColor: '#EF767A',
      },
    },
  },
  loaded: {
    display: "none",
  },
}));

const Loader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    setIsLoading(true);
  }, [pathname]);
  useEffect(() => {
    const id = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    const body = document.body;
    if (isLoading === true) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      clearTimeout(id);
    };
  }, [isLoading]);

  const classes = useStyles();
  return (
    <div className={`${classes.loader} ${!isLoading ? classes.loaded : ""}`}>
      <div>
        <Logo />
        <LinearProgress sx={{ width: "100%", mt: 5 }} />
      </div>
    </div>
  );
};

export default Loader;
