import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  back: {
    position: "fixed",
    width: 60,
    height: 60,
    background: theme.palette.primary.main,
    right: 20,
    bottom: 20,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 99,
    cursor: "pointer",
    [theme.breakpoints.down('sm')]:{
      bottom: 60,
    },
    "& svg": {
      fontSize: 40,
      color: theme.palette.secondary.light,
    },
  },
}));

const BottomToTop = () => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const goToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    let heightToHidden = 250;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  });

  return (
    <>
      {isVisible && (
        <div className={classes.back} onClick={goToTop}>
          <KeyboardArrowUpIcon />
        </div>
      )}
    </>
  );
};

export default BottomToTop;
