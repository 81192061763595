import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import CustomTypography from "../../shared/CustomTypography";
import CustomGrid from "../../shared/CustomGrid";
import team1 from "../../assets/images/vasana-team-1.png";
import team2 from "../../assets/images/vasana-team-2.png";

const useStyles = makeStyles((theme) => ({
  WhuUseMain: {
    padding: [[100, 0, 150, 0]],
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      padding: [[30, 0, 140, 0]],
    },
  },
  heading: {
    "&.MuiTypography-root": {
      marginBottom: 80,
      fontSize: 42,
      fontFamily: "Satoshi-Bold",
      textAlign: "center",
      lineHeight: 1.4,
      [theme.breakpoints.down("md")]: {
        maxWidth: '90%',
        margin: '0 auto',
        fontSize: 36,
        marginBottom: 40,
      },
    },
  },
  vasanaTeamImg: {
    "& img": {
      borderRadius: 24,
      display: "block",
    },
  },
  teamText: {
    "& h4": {
      fontSize: 32,
      fontFamily: "Satoshi-Bold",
      marginBottom: 25,
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
      },
    },
    "& p": {
      fontSize: 20,
      lineHeight: 1.4,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  },
  two: {
    "&.MuiGrid-root": {
      flexDirection: "row-reverse",
      marginTop: 56,
      [theme.breakpoints.down("md")]: {
        marginTop: 76,
      },
    },
  },
}));

const WhyUse = () => {
  const classes = useStyles();
  const team = [
    {
      img: team1,
      heading: "Supercharge Your Team Performance",
      info: "Cohesive teams are more productive. We use behavioral science and behavioral-based communication to improve the quality of interpersonal relationships in teams, enhance clarity, deflate conflicts, reduce friction, build trust and accelerate learning.",
    },
    {
      img: team2,
      heading: "Nurture an Environment of Belonging",
      info: "Workplaces that foster a sense of inclusion and belonging have shown increased organizational productivity. Vasana for teams helps enhance relationships, develop empathy and communicate effectively, driving a cultural change in organizations.",
      className: classes.two,
    },
  ];
  return (
    <div className={classes.WhuUseMain}>
      <Container>
        <CustomTypography
          variant="h3"
          component="h3"
          className={classes.heading}
          id="Vasana-Teams"
        >
          {" "}
          Why Use Vasana for Teams
        </CustomTypography>
        {team.map((data, i) => {
          return (
            <CustomGrid
              container
              key={i}
              spacing={{lg:8, md: 4, xs: 3}}
              alignItems="center"
              className={data.className}
            >
              <CustomGrid item md={6} xs={12}>
                <div className={classes.vasanaTeamImg}>
                  <img src={data.img} alt="img" />
                </div>
              </CustomGrid>
              <CustomGrid item md={6} xs={12}>
                <div className={classes.teamText}>
                  <CustomTypography variant="h4" component="h4">
                    {data.heading}
                  </CustomTypography>
                  <CustomTypography variant="body1" component="p">
                    {data.info}
                  </CustomTypography>
                </div>
              </CustomGrid>
            </CustomGrid>
          );
        })}
      </Container>
    </div>
  );
};

export default WhyUse;
