import React from "react";
import Banner from "../components/MarketForce/banner";
import AboutSection from "../components/MarketForce/aboutSection";
import Approch from "../components/MarketForce/approch";
import MeetSection from "../components/MarketForce/meetSection";
import LogoSlider from "../components/MarketForce/logoSlider";
import CompanyCard from "../components/MarketForce/companyCard";

const MarketForce = () => {
  return (
    <div>
      <Banner />
      <AboutSection />
      <Approch />
      <MeetSection />
      <CompanyCard />
      <LogoSlider />
    
    </div>
  );
};

export default MarketForce;
