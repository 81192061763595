import { List, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, Container } from "@mui/system";
import React from "react";
import CustomButton from "../../shared/CustomButton";
import CustomTypography from "../../shared/CustomTypography";

const useStyles = makeStyles((theme) => ({
  howCanUseMain: {
    padding: [[100, 0, 170, 0]],
    backgroundImage:
      "linear-gradient( rgba(255,255,255,0)  , rgba(234,234,234,1),  rgba(234,234,234,1), rgba(234,234,234,1), rgba(255,255,255,0.10) )",
    [theme.breakpoints.down("md")]: {
      padding: [[50, 0, 145, 0]],
    },
  },
  heading: {
    marginBottom: 60,
    "& h4": {
      fontSize: 31,
      fontFamily: "Satoshi-Bold",
      marginBottom: 30,
      lineHeight: 1.3,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
        maxWidth: '90%',
      },
    },
    "& h2": {
      fontSize: 72,
      fontWeight: 400,
      lineHeight: 1.4,
      maxWidth: 870,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 48,
        maxWidth: '90%',
      },
    },
  },
  mainBox: {
    backgroundColor: theme.palette.secondary.light,
    padding: [40],
    borderRadius: 24,
    [theme.breakpoints.down("md")]: {
      padding: [[40, 20]]
    },
    "&:not(:last-child)": {
      marginBottom: 20,
    }
  },
  headingMain: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 30,
    "& h3": {
      fontSize: 42,
      fontFamily: "Satoshi-Medium",
      lineHeight: 1.3,
      color: theme.palette.secondary.main,

      [theme.breakpoints.down("md")]: {
        fontSize: 36,
      },
    },
  },
  lists: {
    "& li": {
      padding: [[0, 16]],
      position: "relative",
      fontSize: 24,
      color: theme.palette.secondary.main,
      lineHeight: 1.72,
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },
      "&:before": {
        position: "absolute",
        content: "''",
        width: 5,
        height: 5,
        backgroundColor: "#000",
        borderRadius: "100%",
        left: 0,
        top: 18,
        [theme.breakpoints.down("md")]: {
          top: 14,
        },
      },
    },
  },
  boxes: {
    padding: [[0, 40, 0, 100]],
    [theme.breakpoints.down("md")]: {
      padding: [0],
    },
  },
  btn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 33px",
    },
  },
}));

const HowCanUse = () => {
  const classes = useStyles();
  const useVasna = [
    {
      heading: "Vasana Type Assessment",
      btnText: "Work with Us",
      subList: [
        "Individual Vasana behavioral type discovery",
        "Team Vasana type composition discovery.",
        " Learn how your Vasana type impacts individual choices, preferences, and interactions with colleagues and customers.",
      ],
    },
    {
      heading: "Coaching And Training",
      btnText: "Work with Us",
      subList: [
        "Team performance training ",
        "Leadership capabilities training ",
        "Executive and team coaching ",
        "Business development training",
      ],
    },
  ];
  return (
    <div className={classes.howCanUseMain}>
      <Container>
        <div className={classes.heading}>
          <CustomTypography variant="h4" component="h4">
            How You Can Use Vasana for Teams
          </CustomTypography>
          <CustomTypography variant="h2" component="h2">
            Transform Your People, Fuel Your Business
          </CustomTypography>
        </div>
        <div className={classes.boxes}>
          {useVasna.map((data, i) => {
            return (
              <div key={i} className={classes.mainBox}>
                <div className={classes.headingMain}>
                  <CustomTypography variant="h3" component="h3">
                    {" "}
                    {data.heading}{" "}
                  </CustomTypography>
                  <CustomButton
                    variant="outlined"
                    sx={{ display: { xs: "none", md: "block" } }}
                    className={classes.btn}
                  >
                    {" "}
                    <a href="#footer">{data.btnText}{" "}</a>
                  </CustomButton>
                </div>
                <List className={classes.lists}>
                  {data?.subList?.map((data, i) => {
                    return <ListItem key={i}>{data}</ListItem>;
                  })}
                </List>
                <Box mt={3} align="center" sx={{ display: { xs: "block", md: "none" } }}>
                <a href="#footer">
                    <CustomButton
                      variant="outlined"
                      className={classes.btn}
                    >
                    {data.btnText}{" "}
                    </CustomButton>
                  </a>
                </Box>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default HowCanUse;
