import { Box, Typography, /* Link */ } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { Navigation, Thumbs } from "swiper";
import { SwiperSlide } from "swiper/react";
import osImg1 from "../../assets/images/os-bg-1.png";
import osImg2 from "../../assets/images/os-bg-2.png";
import osImg3 from "../../assets/images/os-bg-3.png";
import osImg4 from "../../assets/images/os-bg-4.png";
import osImg5 from "../../assets/images/os-bg-5.png";
import CustomSwiper from "../../shared/CustomSwiper";
import CustomTypography from "../../shared/CustomTypography";

const useStyles = makeStyles((theme) => ({
  productizeSliderMain: {
    padding: [[150, 0]],
    backgroundImage:
      "linear-gradient(to bottom, rgba(255,255,255,0)  , rgba(234,234,234,1),rgba(234,234,234,1),rgba(234,234,234,1) )",
      color: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      padding: [[100, 0]],
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 42,
      fontFamily: "Satoshi-Bold",
      lineHeight: 1.4,
      marginBottom: [82],
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        marginBottom: 60,
      },
    },
  },
  productizeSlider: {},
  slides: {
    borderRadius: [24],
    padding: [[70, 24, 50]],
    height: 595,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      padding: [[50, 20, 40,20]],
      height: 530,
    },
    "& span": {
      marginTop: "auto",
      fontSize: 24,
      fontWeight: 700,
      textDecoration: "underline",
    },
  },
  slide1: {
    background: `#C5D9FE url(${osImg1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      backgroundSize: '120% 130% ',
    }
  },
  slide2: {
    background: `rgb(239,200,182) url(${osImg2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  slide3: {
    background: `#A2E5C4 url(${osImg3})`,
    backgroundSize: "contain",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
  },
  slide4: {
    background: `#B8A2E0 url(${osImg4})`,
    backgroundSize: "contain",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
  },
  slide5: {
    background: `#F1F2A8 url(${osImg5})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom center",
    backgroundRepeat: "no-repeat",
  },
  slideTitle: {
    "&.MuiTypography-root": {
      fontSize: 60,
      color: theme.palette.secondary.main,
      fontFamily: "Satoshi-Medium",
      flex: 1,
      [theme.breakpoints.down("lg")]: {
        fontSize: 40,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 40,
      },
      [theme.breakpoints.down("480")]: {
        fontSize: 36
      },
      "& sup": {
        fontSize: 30,
      },
    },
  },
  osInfo: {
    "&.MuiTypography-root": {
      fontSize: 24,
      lineHeight: '32px',
      fontFamily: "Satoshi-Regular",
      marginBottom: 34,
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },
    },
  },
  linkBtn: {
    marginTop: 'auto',
    padding: 0,
    fontSize: 24,
    fontFamily: "Satoshi-Bold",
    color: theme.palette.secondary.main,
    "&:hover": {
      background: "transparent",
    },
  },
  description: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    "& .MuiButtonBase-root": {
      marginTop: "auto",
    },
  },
  productizeContainer: {
    "&.MuiContainer-root": {
      padding: 0,
    },
  },
  swiperS: {
    cursor: 'pointer',
    paddingRight: '12%',
    [theme.breakpoints.down("1800")]: {
      paddingRight: "8%",
    },
    [theme.breakpoints.down("1750")]: {
      paddingRight: "4%",
    },
    [theme.breakpoints.down("1700")]: {
      paddingRight: "0%",
    },
    [theme.breakpoints.down("1600")]: {
      paddingRight: "47%",
    },
    [theme.breakpoints.down("1480")]: {
      paddingRight: "45%",
    },
    [theme.breakpoints.down("1440")]: {
      paddingRight: "40%",
    },
    [theme.breakpoints.down("1380")]: {
      paddingRight: "37%",
    },
    [theme.breakpoints.down("1340")]: {
      paddingRight: "35%",
    },
    [theme.breakpoints.down("1300")]: {
      paddingRight: "30%",
    },
    [theme.breakpoints.down("1199")]: {
      paddingRight: "14%",
    },
    [theme.breakpoints.down("1024")]: {
      paddingRight: "8%",
    },
    [theme.breakpoints.down("480")]: {
      paddingRight: "12%",
    },
    "& .swiper-button-next": {
      bottom: 0,
      background: '#EF767A',
      height: 40,
      width: 40,
      "&.swiper-button-disabled":{
        opacity: 0,
      },
      "&:after":{
        color: "#fff",
        fontSize: 27, 
      },
      [theme.breakpoints.down("sm")]: {
        height: 25,
        width: 25,
        "&:after":{
          fontSize: 17, 
        },
      },
    },
    "& .swiper-button-prev": {
      background: '#EF767A',
      left: '10px',
      height: 40,
      width: 40,
      "&.swiper-button-disabled":{
        opacity: 0,
      },
      "&:after":{
        color: "#fff",
        fontSize: 27, 
      },
      [theme.breakpoints.down("sm")]: {
        height: 25,
        width: 25,
        "&:after":{
          fontSize: 17, 
        },
      },
    },
    "& .swiper-wrapper": {
      marginLeft: "20%",
      [theme.breakpoints.down("1800")]: {
        marginLeft: "17%",
      },
      [theme.breakpoints.down("1750")]: {
        marginLeft: "14%",
      },
      [theme.breakpoints.down("1700")]: {
        marginLeft: "13%",
      },
      [theme.breakpoints.down("1650")]: {
        marginLeft: "9%",
      },
      [theme.breakpoints.down("1600")]: {
        marginLeft: "22%",
      },
      [theme.breakpoints.down("1550")]: {
        marginLeft: "19%",
      },
      [theme.breakpoints.down("1480")]: {
        marginLeft: "13%",
      },
      [theme.breakpoints.down("1440")]: {
        marginLeft: "12%",
      },
      [theme.breakpoints.down("1380")]: {
        marginLeft: "8%",
      },
      [theme.breakpoints.down("1340")]: {
        marginLeft: "7%",
      },
      [theme.breakpoints.down("1300")]: {
        marginLeft: "40px",
      },
      [theme.breakpoints.down("600")]: {
        marginLeft: "20px",
      },
      "& .swiper-slide":{
        cursor: "default",
        [theme.breakpoints.up("991")]: {
          width: '450px !important',
        },
        [theme.breakpoints.up("1280")]: {
          width: '490px !important',
        },
      }
    },
  },
}));

const Productize = () => {
  const classes = useStyles();
  const productize = [
    {
      title: "Digital",
      sup: "OS",
      info: "Deliver incredible digital experiences with five-star human-centered digital journeys at a five-bar level of technology and operational efficiency.",
      className: classes.slide5,
      linkBtn: "Find Out More",
    },
    {
      title: "Design",
      sup: "OS",
      info: "Envision and design usable and scalable successful digital experiences, bringing together a data-driven, human-first approach and best design practices and standards.",
      className: classes.slide4,
      linkBtn: "Find Out More",
    },
    {
      title: "Work",
      sup: "OS",
      info: "Reimagine traditional ways of working and evolve for the new world. Craft differentiated customer experiences with brilliant processes and smart technologies.",
      linkBtn: "Find Out More",
      className: classes.slide1,
    },
    {
      title: "Culture",
      sup: "OS",
      info: "Reshape organizational culture, processes, and operations with a human-centered approach to win the hearts and minds of employees.",
      className: classes.slide2,
      linkBtn: "Find Out More",
    },
    {
      title: "Engineering",
      sup: "OS",
      info: "Accelerate your future with emerging technology and practices. Leverage best-fit technologies with scalable solutions architected, developed and deployed for you.",
      className: classes.slide3,
      linkBtn: "Find Out More",
    },
  ];
  return (
    <div className={classes.productizeSliderMain}>
      <Container maxWidth="false" className={classes.productizeContainer}>
        <CustomTypography
          align="center"
          variant="h3"
          className={classes.heading}
        >
          Our Expertise Productized Into <br></br> Operating Systems (OS)
        </CustomTypography>
        <Box className={classes.productizeSlider}>
          <CustomSwiper
            navigation={{ clickable: true }}
            loop={false}
            modules={[Navigation, Thumbs]}
            className={classes.swiperS}
            initialSlide={0}
            spaceBetween={20}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              480: {
                slidesPerView: 1.2,
              },
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              1199: {
                slidesPerView: 2,
              },
              1600: {
                slidesPerView: 3,
              },
            }}
          >
            {productize.map((data, i) => (
              <SwiperSlide key={i} ><Link
              className={classes.linkBtn}
                to={{
                  pathname: "/about",
                  hash: "#solution-carousel",
                }}
                state={i}
              >
                <div className={`${classes.slides} ${data.className}`}>
                  <CustomTypography variant="h2" className={classes.slideTitle}>
                    {data.title}
                    <sup>{data.sup}</sup>
                  </CustomTypography>
                  <div className={classes.description}>
                    <CustomTypography
                      variant="body1"
                      className={classes.osInfo}
                    >
                      {data.info}
                    </CustomTypography>
                    {/* <Link href={`/about#solution-carousel/${i}`} state={i} className={classes.linkBtn}>{data.linkBtn}</Link> */}
                    <Typography component="span">{data.linkBtn}</Typography>
                  </div>
                </div>
                      </Link>
              </SwiperSlide>
            ))}
          </CustomSwiper>
        </Box>
      </Container>
    </div>
  );
};

export default Productize;
