import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import shape from "../../assets/images/shape1.svg";
import CustomButton from "../../shared/CustomButton";
import CustomGrid from "../../shared/CustomGrid";
import CustomTypography from "../../shared/CustomTypography";

const useStyles = makeStyles((theme) => ({
  aboutWrap: {
    padding: "150px 0 100px 0",
    [theme.breakpoints.down("md")]: {
      padding: [[100, 0]],
    },
  },
  aboutText: {
    "&.MuiTypography-root": {
      fontSize: 32,
      marginBottom: 40,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 32,
      },
    },
  },

  storyLogo: {
    position: 'sticky',
    top: 120, 
    [theme.breakpoints.down("md")]: {
      marginBottom: 35,
      display: "flex",
      justifyContent: "start",
    },
  },
  heading: {
    position: "relative",
    animation: "up 2s ease-in-out infinite",
    "& img":{
      [theme.breakpoints.down('md')]:{
        width: 166,
        height: 166,
      }
    },
    "& p": {
      color: theme.palette.natural.main,
      fontSize: 24,
      position: "absolute",
      top: "50%",
      left: 0,
      right: 0,
      fontFamily: 'Satoshi-Medium',
      transform: "translateY(-50%)",
      [theme.breakpoints.down('md')]:{
        fontSize: '15.4px'
      },
      "& span": {
        display: "block",
        fontSize: 34,
        fontFamily: "Satoshi-Bold",
        [theme.breakpoints.down('md')]:{
          fontSize: '21.82px'
        }
      },
    },
  },
  headingM: {
    [theme.breakpoints.down("md")]: {
      marginBottom: 35,
      display: "flex",
      justifyContent: "start",
    },
  },
  btn:{
    "&.MuiButtonBase-root.MuiButton-root": {
      padding:" 11.5px 24.5px",
      [theme.breakpoints.down("md")]: {
        padding:" 8.5px 20px",
      },
    },
  }
}));

export default function WeAre() {
  const classes = useStyles();
  return (
    <div className={classes.aboutWrap}>
      <Container>
        <CustomGrid container>
          <CustomGrid item xs={12} md={5} align="center">
            <div className={classes.storyLogo}>
              <div className={classes.heading}>
                <img src={shape} width="359" height="356" alt="shape" />
                <CustomTypography variant="body1" component="p">
                  We are <span>REDAZURE</span>
                </CustomTypography>
              </div>
            </div>
          </CustomGrid>
          <CustomGrid item xs={12} md={7}>
            <CustomTypography
              variant="body1"
              component="p"
              className={classes.aboutText}
            >
              A human-first company, we awaken and unleash the full potential of amazing people with brilliant processes, smart technology, and a winning culture. We explore avenues, digital and beyond.
            </CustomTypography>
            <CustomTypography
              variant="body1"
              component="p"
              className={classes.aboutText}
            >
              Our tools and practices help people and companies accelerate transformation and growth in an agile, efficient, and scalable way with innovation and velocity. 
            </CustomTypography>
            
            <CustomButton variant="outlined" className={classes.btn} color="primary">
              <Link to="/who-we-are">Get to Know Us</Link>
            </CustomButton>
          </CustomGrid>
        </CustomGrid>
      </Container>
    </div>
  );
}
