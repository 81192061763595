import React from "react";
import { Chip, Container, Grid, Typography } from "@mui/material";
import CustomTypography from "../../shared/CustomTypography";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { caseStudies } from "../../description/CaseStudies";
import { convertToUri } from "../../utils/javascript";

const useStyles = makeStyles((theme) => ({
  blogSection: {
    padding: "130px 0",
    [theme.breakpoints.down("md")]: {
      padding: [[90, 0, 100]],
    },
    [theme.breakpoints.down("sm")]: {
      padding: [[90, 0, 60]],
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontFamily: "Satoshi-Bold",
      fontSize: 32,
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
  },
  blogImage: {
    marginTop: "1rem",
    height: 650,
    [theme.breakpoints.down("md")]: {
      height: 400,
    },
    [theme.breakpoints.down("sm")]: {
      height: 275,
    },
  },
  chip: {
    "&.MuiChip-root": {
      fontSize: 14,
      margin: [[20, 0, 20]],
    },
  },
  infoP: {
    "&.MuiTypography-root": {
      fontSize: 18,
      marginBottom: "1rem",
      fontFamily: "Satoshi-Medium",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
}));

function BlogDetails() {
  const classes = useStyles();
  const { slug } = useParams();
  const caseStudy = caseStudies.find(({ infoHeading }) => convertToUri(infoHeading) === slug);

  document.body.scrollTop = document.documentElement.scrollTop = 0;

  return (
    <section className={classes.blogSection}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.heading}>
              {caseStudy?.infoHeading}
            </Typography>
            <Chip label={caseStudy?.infoTag} className={classes.chip} variant="outlined" />
            {caseStudy?.blogContent?.map((blogContent, i) => {
              return (
                <CustomTypography variant="body1" color="secondary" className={classes.infoP} component="p">
                  {blogContent}
                </CustomTypography>
              );
            })}
            <iframe
              width="100%"
              className={classes.blogImage}
              src={caseStudy?.iframe}
              title="Veresh Sita, Alaska Airlines - ChefConf 2016 Keynote"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default BlogDetails;
