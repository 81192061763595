import React from "react";
import Banner from "./banner";
import WhyUse from "./WhyUse";
import HowCanUse from "./HowCanUse";

const index = () => {
  return (
    <div>
      <Banner />
      <WhyUse />
      <HowCanUse />
    </div>
  );
};

export default index;
