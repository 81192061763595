import { Box, Container, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import travis from "../../assets/images/tarvish.png";
import CustomButton from "../../shared/CustomButton";
import CustomGrid from "../../shared/CustomGrid";
import CustomTypography from "../../shared/CustomTypography";
import colorLayer from "../../assets/images/footer-layer.webp";

const useStyles = makeStyles((theme) => ({
  bannerWrap: {
    padding: "100px 0",
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("xl")]: {
      padding: "90px 0 100px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "70px 0 80px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "40px 0 60px",
    },
    [theme.breakpoints.down("480")]: {
      overflow: "hidden",
    },
  },
  bannerText: {
    "& h3": {
      color: theme.palette.secondary.main,
      fontSize: 42,
      marginBottom: 30,
      fontFamily: "Satoshi-Bold",
      lineHeight: 1.3,
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        marginBottom: 20,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
      },
    },
    "& p": {
      fontSize: 18,
      marginBottom: 40,
      maxWidth: 550,
      lineHeight: "24px",
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        marginBottom: 20,
      },
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100%",
      },
    },
    "& h6": {
      fontFamily: "Satoshi-Medium",
      marginBottom: 24,
      lineHeight: 1.4,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  },
  btn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: " 11.5px 33.5px",
      [theme.breakpoints.down("md")]: {
        padding: " 8.5px 25px",
      },
    },
  },
  imgInnerWrapper: {
    borderRadius: 14,
    position: "relative",
    width: "fit-content",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  travis: {
    borderRadius: 20,
    maxHeight: 386,
    objectFit: "cover",
  },
  colorImage: {
    position: "absolute",
    filter: "blur(140px)",
    zIndex: 0,
    transform: "rotate(80deg)",
    top: "-7%",
    maxWidth: "480px",
    width: "100%",
    height: "480px",
    right: 0,
    opacity: 0.3,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
const MeetSection = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.bannerWrap} id="meet-travis">
        <img src={colorLayer} alt="colorLayer" className={classes.colorImage} />
        <Container>
          <CustomGrid container spacing={4} justifyContent="center" alignItems="center">
            <CustomGrid item xs={12} md={6}>
              <Box className={classes.bannerText} textAlign={{ md: "start", xs: "center" }}>
                <CustomTypography variant="h3" component="h3">
                  Meet Travis Carson
                </CustomTypography>
                <CustomTypography variant="body1" component="p">
                  A 5-time nationally-ranked junior tennis player, 7-time nationally-ranked triathlete & 3-time Ironman finisher, Travis Carson is a
                  lifelong learner and entrepreneur. The driving force behind Market Force, he combines the power of the Market Force system with his
                  significant business ownership experience, degrees in law and business, and 15+ years in business coaching to help companies solve
                  their most pressing business issues and accelerate performance.
                </CustomTypography>
                <Box display={{ md: "flex", xs: "none" }} mt={2.75}>
                  <a href="mailto:travis.carson@redazure.com">
                    <CustomButton className={classes.btn} variant="outlined" color="primary">
                      Work with Travis
                    </CustomButton>
                  </a>
                </Box>
              </Box>
            </CustomGrid>
            <CustomGrid item xs={12} md={6} display="flex" justifyContent="center">
              <Stack position="relative">
                <img src={travis} alt="travis" className={classes.travis} />
              </Stack>
            </CustomGrid>
          </CustomGrid>
          <Box display={{ md: "none", xs: "flex" }} justifyContent={"center"} mt={2.75}>
            <a href="mailto:travis.carson@redazure.com">
              <CustomButton className={classes.btn} variant="outlined" color="primary">
                Work with Travis
              </CustomButton>
            </a>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MeetSection;
