import CloseIcon from "@mui/icons-material/Close";
import { Hidden, IconButton, List, ListItem, Toolbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { makeStyles } from "@mui/styles";
import { Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import logoDark from "../assets/images/logo-dark.svg";
import CustomTypography from "../shared/CustomTypography";
import CustomButton from "../shared/CustomButton";

const useStyles = makeStyles((theme) => ({
  mainAppbar: {
    "&.MuiToolbar-root": {
      minHeight: 65,
      [theme.breakpoints.down("sm")]: {
        paddingRight: "8px",
      },
    },
  },
  active: {
    fontWeight: 700,
  },
  mainMenu: {
    marginLeft: "auto",
    "&.MuiList-root": {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
      "& .MuiListItem-root": {
        width: "auto",
        padding: "5px 25px",
        [theme.breakpoints.down("1024")]: {
          padding: "5px 16px",
        },
        [theme.breakpoints.down("965")]: {
          padding: "12px 10px",
        },
        "& a": {
          color: "#000",
          textDecoration: "none",
        },
      },
    },
  },
  menuBtn: {
    "&.MuiButtonBase-root": {
      margin: 0,
      padding: 0,
      [theme.breakpoints.down("md")]: {
        position: "relative",
        paddingRight: 16,
      },
      "&::after": {
        content: "''",
        position: "absolute",
        height: 2,
        width: 16,
        right: 0,
        top: 9,
        background: "#fff",
      },
      "&::before": {
        content: "''",
        position: "absolute",
        height: 2,
        width: 16,
        right: 0,
        top: 16,
        background: "#fff",
      },
    },
  },
  toggle: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      left: 0,
      top: "-16px",
      backgroundColor: "#fff",
      padding: [[16, 24, 50, 50]],
      display: "none",
      marginLeft: -24,
      marginRight: -24,
      right: 0,
      height: "calc(100vh + 16px)",
      [theme.breakpoints.down("sm")]: {
        padding: [[16, 24, 50, 29]],
        marginLeft: -16,
        marginRight: -16,
      },
    },
  },
  menuOpen: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  headerColor: {
    "&.MuiPaper-root": {
      position: "fixed",
      background: "#fff",
      boxShadow: "0 0 10px 0px rgb(222, 222, 222)",
      borderBottom: "#fff",
      WebkitAnimation: "slideInDown 0.65s cubic-bezier(0.23, 1, 0.32, 1)",
      MozAnimation: "slideInDown 0.65s cubic-bezier(0.23, 1, 0.32, 1)",
      OAnimation: "slideInDown 0.65s cubic-bezier(0.23, 1, 0.32, 1)",
      animation: "slideInDown 0.65s cubic-bezier(0.23, 1, 0.32, 1)",
      WebkitAnimationFillMode: "none",
      MozAnimationFillMode: "none",
      animationFillMode: "none",
      "& button": {
        [theme.breakpoints.down("md")]: {
          color: "#000",
        },
        "&:after": {
          background: "#000",
        },
        "&:before": {
          background: "#000",
        },
      },
    },
  },
  menuBarColor: {
    "&.MuiButtonBase-root": {
      "&:after": {
        background: "#000",
      },
      "&:before": {
        background: "#000",
      },
    },
  },
  menuColor: {
    "&.MuiList-root": {
      "& .MuiListItem-root": {
        "& a": {
          color: theme.palette.secondary.main,
          textDecoration: "none",
        },
      },
    },
  },
  menuBColor: {
    "&.MuiButtonBase-root": {
      color: theme.palette.primary.main,
    },
  },
  logo: {
    "& a": {
      display: "flex",
    },
    "& img": {
      [theme.breakpoints.down("1340")]: {
        marginLeft: 26,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 13,
      },
    },
  },
  lightMenu: {
    "&.MuiList-root": {
      "& .MuiListItem-root": {
        "& a": {
          [theme.breakpoints.up("md")]: {
            color: "#fff",
          },
        },
      },
    },
    "&$menuColor": {
      "&.MuiList-root": {
        "& .MuiListItem-root": {
          "& a": {
            color: "#000",
          },
        },
      },
    },
  },
  headerbtn: {
    "&.MuiButtonBase-root": {
      [theme.breakpoints.down("1000")]: {
        padding: [[12, 23]],
      },
    },
  },
  toggleHeader: {
    display: "none",
    minHeight: 65,
    "& a": {
      display: "flex",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: [[0, 0, 5, 0]],
    },
  },
}));

const menubar = [
  {
    title: "What We Do",
    to: "/about",
  },
  {
    title: "Who We Are",
    to: "/who-we-are",
  },
  {
    title: "Vasana",
    to: "/vasana",
  },
  {
    title: "Market Force",
    to: "/market-force",
  },
  {
    title: "Careers",
    to: "/careers",
  },
  // {
  //   title: "Blogs",
  //   to: "/blog",
  // },
];

export default function Header() {
  const [menuToggle, setMenuToggle] = useState();
  const classes = useStyles();
  const { pathname } = useLocation();
  const isHome = ["/"].includes(pathname);
  const isBorder = ["/vasana", "/careers", "/market-force"].includes(pathname);
  const hideBorder = pathname.includes("/blogs");
  const [color, setColor] = useState(false);
  useEffect(() => {
    if (menubar.map((el) => el.to).includes(pathname)) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [pathname]);
  const changeColor = () => {
    if (window.scrollY >= 20) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    var topheaderHeight = document.querySelector("#topNavBar").offsetHeight;
    document.querySelectorAll("a.learnMore").forEach(function (anchor) {
      anchor.addEventListener("click", function (event) {
        if (pathname.replace(/^\//, "") === this.pathname.replace(/^\//, "")) {
          var target = document.querySelector(this.hash),
            speed = parseInt(this.getAttribute("data-speed")) || 800;
          target = target ? target : document.querySelector("[name=" + this.hash.slice(1) + "]");
          if (target) {
            event.preventDefault();
            var offsetTop = target.getBoundingClientRect().top + window.scrollY - topheaderHeight + 1;
            window.scrollTo({
              top: offsetTop,
              behavior: "smooth",
            });
          }
        }
      });
    });
  }, []);

  const handleMenuToggle = () => {
    setMenuToggle(!menuToggle);
    const body = document.body;
    if (!menuToggle === true) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <>
      <AppBar
        id="topNavBar"
        className={`${classes.header} ${color && classes.headerColor}`}
        position="absolute"
        elevation={0}
        sx={{
          backgroundColor: "transparent",
          py: 2,
          borderBottom: `${isHome ? "1px solid #fff" : `${isBorder ? "1px solid rgba(0,0,0,0)" : !hideBorder ? "1px solid #000" : "none"}`}`,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters className={classes.mainAppbar}>
            <div className={classes.logo}>
              <Link to="/">
                {isHome && !color ? <img width="163" height="32" src={logo} alt="logo" /> : <img width="163" height="38" src={logoDark} alt="logo" />}
              </Link>
            </div>
            <Stack ml="auto" direction="row">
              <Hidden mdUp>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 2, color: `${isHome ? "#fff" : "#000"}` }}
                  className={`${classes.menuBtn} ${color ? classes.menuBColor : classes.menuBtn} ${!isHome ? classes.menuBarColor : ""}`}
                  onClick={() => handleMenuToggle()}
                >
                  <CustomTypography variant="body1" mr={1}>
                    Menu
                  </CustomTypography>
                </IconButton>
              </Hidden>
              <div className={`${classes.toggle} ${menuToggle ? classes.menuOpen : ""}`}>
                <div className={classes.toggleHeader}>
                  <Link to="/">
                    <img src={logoDark} alt="logo" height="32" width="163" onClick={() => handleMenuToggle(!menuToggle)} />
                  </Link>
                  <CloseIcon sx={{ fontSize: 30, color: "#000" }} onClick={() => handleMenuToggle(!menuToggle)} />
                </div>
                <List className={`${isHome ? classes.lightMenu : ""} ${classes.mainMenu} ${color ? classes.menuColor : ""}`}>
                  {menubar.map((data, i) => {
                    return (
                      <ListItem key={i}>
                        <Link
                          className={pathname === data.to ? classes.active : ""}
                          to={data.to}
                          {...{
                            ...(window.innerWidth <= 900 && {
                              onClick: () => handleMenuToggle(!menuToggle),
                            }),
                          }}
                        >
                          {data.title}
                        </Link>
                      </ListItem>
                    );
                  })}
                </List>
                <Stack ml={{ md: 2, xs: 0 }} mt={{ xs: 2, md: 0 }} direction="row" alignItems="center">
                  <a target="_blank" rel="noreferrer" href="mailto:hello@redazure.com">
                    <CustomButton className={classes.headerbtn} variant="outlined" color="primary">
                      Get in Touch
                    </CustomButton>
                  </a>
                </Stack>
              </div>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
