import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import React from "react";
import shape from "../../assets/images/shape1.svg";
import CustomGrid from "../../shared/CustomGrid";
import CustomTypography from "../../shared/CustomTypography";

const useStyles = makeStyles((theme) => ({
  aboutWrap: {
    padding: "100px 0",
    [theme.breakpoints.down("md")]: {
      padding: [[70, 0,120,0]],
    },
  },
  aboutText: {
    "&.MuiTypography-root": {
      fontSize: 32,
      marginBottom: 40,
      color: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        fontSize: 32,
      },
      "& span":{
        fontWeight: 800
      }
    },
   
  },
  heading: {
    position: "relative",
    animation: "up 2s ease-in-out infinite",
    "& img":{
      [theme.breakpoints.down('md')]:{
        width: 166,
        height: 166,
      }
    },
    [theme.breakpoints.down("md")]: {},
    "& p": {
      color: theme.palette.natural.main,
      fontSize: 24,
      position: "absolute",
      top: "50%",
      left: 0,
      right: 0,
      fontFamily: 'Satoshi-Medium',
      transform: "translateY(-50%)",
      [theme.breakpoints.down('md')]:{
        fontSize: 15.4
      }
    },
  },
  storyLogo: {
    position: 'sticky',
    top: 120, 
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "start",
    },
  },
  subTextline: {
    "&.MuiTypography-root": {
      fontSize: 24,
      color: theme.palette.secondary.main,
        [theme.breakpoints.down('md')]:{
          fontSize: 20
      },
    },
  },
}));

export default function WeAre() {
  const classes = useStyles();
  const info = [
    {
      info: "Yes, our name has two colors in it. No, we weren’t being unimaginative. If you don’t love our origin story, we will change our name.* ",
    },
    {
      info: " Our founder, Veresh, was born and brought up in South Africa. The street he grew up on <span>—Azure</span> Street.  This is where his foundation was laid as a person and future leader. But it was his nature to explore, learn, and find new worlds. And his trusted companion on these journeys? The <span>red </span>  family car.",
    },
    {
      info: "Our name,<span> RedAzure,</span> is an ode to two constants from Veresh’s childhood that taught him a life lesson— Build a strong foundation but never stop exploring what lies beyond. ",
    },
    {
      subline: "*That’s a lie. We’ll never change our name.",
    },
  ];
  return (
    <div className={classes.aboutWrap}>
      <Container>
        <CustomGrid container spacing={4}>
          <CustomGrid item xs={12} md={5} align="center">
            <div className={classes.storyLogo}>
              <div className={classes.heading}>
                <img src={shape} width="359" height="356" alt="shape" />
                <CustomTypography variant="body1" component="p">
                  The <br></br> RedAzure <br></br> Story
                </CustomTypography>
              </div>
            </div>
          </CustomGrid>
          <CustomGrid item xs={12} md={7}>
            {info.map((data, i) => {
              return (
                <div>
                  <CustomTypography
                    variant="body1"
                    component="p"
                    className={classes.aboutText}
                    dangerouslySetInnerHTML={{ __html: data.info}}
                    key={i}
                  >
                    {/* {data.info} */}
                  </CustomTypography>
                  <CustomTypography
                    variant="body1"
                    component="p"
                    className={classes.subTextline}
                  >
                    {data.subline}
                  </CustomTypography>
                </div>
              );
            })}
          </CustomGrid>
        </CustomGrid>
      </Container>
    </div>
  );
}
