import React, { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { ParallaxProvider } from "react-scroll-parallax";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import WhoWeAre from "./pages/WhoWeAre";
import Vasana from "./pages/Vasana";
import Careers from "./pages/Careers";
import Blogs from "./pages/Blogs";
import BottomToTop from "./components/BottomToTop";
import Loader from "./components/Loader";
import MarketForce from "./pages/MarketForce";

const theme = createTheme({
  palette: {
    primary: {
      main: "#084B83",
      light: "#FAFAFA",
    },
    secondary: {
      main: "#121212",
      light: "#ffffff",
      white: "#fff",
    },
    natural: {
      main: "#EF767A",
      light: "#F4F4F4",
    },
    black: {
      primary: "#000000",
    },
  },
  typography: {
    fontFamily: "Satoshi-Regular",
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          "&.MuiContainer-maxWidthLg": {
            maxWidth: 1280,
          },
        },
      },
    },
  },
});

function App() {
  return (
    <div>
      <ParallaxProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Loader />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/who-we-are" element={<WhoWeAre />} />
              <Route path="/vasana" element={<Vasana />} />
              <Route path="/market-force" element={<MarketForce />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/blogs/:slug" element={<Blogs />} />
            </Routes>
            <Footer />
            <BottomToTop />
          </Router>
        </ThemeProvider>
      </ParallaxProvider>
    </div>
  );
}

export default App;
