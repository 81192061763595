import React from "react";
import { makeStyles } from "@mui/styles";
import { Navigation, Thumbs, Autoplay } from "swiper";
import CustomTypography from "../../shared/CustomTypography";
import { Container } from "@mui/system";
import { SwiperSlide } from "swiper/react";
import CustomSwiper from "../../shared/CustomSwiper";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import { caseStudies } from "../../description/CaseStudies";
import { convertToUri } from "../../utils/javascript";

const useStyles = makeStyles((theme) => ({
  caseStudiesM: {
    padding: [[100, 0, 150, 0]],
    [theme.breakpoints.down("md")]: {
      padding: [[50, 0, 150, 0]],
    },
  },
  caseH: {
    "&.MuiTypography-root": {
      fontSize: 42,
      fontFamily: "Satoshi-Bold",
      marginBottom: 54,
      lineHeight: 1.4,
      [theme.breakpoints.down("md")]: {
        fontSize: 36,
        marginBottom: 44,
      },
    },
  },
  slides: {
    position: "relative",
    paddingBottom: "56.25%",
    [theme.breakpoints.down("sm")]: {
      height: 200,
    },
    "& iframe": {
      position: "absolute",
      height: "100%",
    },
    "& img": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
      borderRadius: 24,
    },
  },
  infoMain: {
    display: "flex",
    marginTop: 30,
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
    "& img": {
      height: 42,
      width: 42,
    },
    "& h2": {
      fontSize: 22,
      fontWeight: 600,
      marginBottom: 10,
    },
    "& .MuiChip-root": {
      marginBottom: 10,
    },
  },
  infoP: {
    "&.MuiTypography-root": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      fontSize: 20,
      lineHeight: "27px",
      marginRight: 42,
      letterSpacing: "-0.2px",
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        marginRight: 24,
      },
    },
  },
  swiperS: {
    cursor: "pointer",
    paddingRight: "18%",
    [theme.breakpoints.down("xl")]: {
      paddingRight: "8%",
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: "6%",
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: "5%",
    },
    [theme.breakpoints.down("768")]: {
      paddingRight: "18%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "15%",
    },
    "& .swiper-wrapper": {
      marginLeft: "-5%",
      [theme.breakpoints.down("xl")]: {
        marginLeft: "-19%",
      },
      [theme.breakpoints.down("1401")]: {
        marginLeft: "-20%",
      },
      [theme.breakpoints.down("1350")]: {
        marginLeft: "-24.5%",
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: "-22.5%",
      },
      [theme.breakpoints.down("900")]: {
        marginLeft: "-22.5%",
      },
      [theme.breakpoints.down("768")]: {
        marginLeft: "5.5%",
      },
      [theme.breakpoints.down("601")]: {
        marginLeft: "5.5%",
      },
    },
  },
  link: {
    flex: "none",
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: 30,
        height: 30,
      },
    },
  },
  blogImage: {
    borderRadius: 20,
  },
  secoundP: {
    "&.MuiTypography-root": {
      marginTop: 10,
    },
  },
}));

const CaseStudies = () => {
  const classes = useStyles();
  return (
    <div className={classes.caseStudiesM}>
      <Container maxWidth="lg">
        <CustomTypography variant="h3" color="secondary" className={classes.caseH}>
          Insights and Case Studies{" "}
        </CustomTypography>
      </Container>
      <CustomSwiper
        loop={false}
        spaceBetween={80}
        className={classes.swiperS}
        centeredSlides={true}
        modules={[Navigation, Thumbs, Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 2,
            spaceBetween: 80,
          },
        }}
      >
        {caseStudies.map((data, i) => {
          console.log("data ->", data);
          return (
            <SwiperSlide key={i}>
              <Link
                to={`${data.link}/${convertToUri(data.infoHeading)}`}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
                // path={data.link}
                state={data}
              >
                <div className={`${classes.slides} ${data.className}`}>
                  <iframe
                    width="100%"
                    className={classes.blogImage}
                    src={data.iframe}
                    title="Veresh Sita, Alaska Airlines - ChefConf 2016 Keynote"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className={classes.infoMain}>
                  <div>
                    <CustomTypography variant="body1" color="secondary" component="h2">
                      {data.infoHeading}
                    </CustomTypography>

                    <Chip label={data.infoTag} variant="outlined" />
                    <CustomTypography variant="body1" color="secondary" className={classes.infoP} component="p" key={i}>
                      {data.blogContent}
                    </CustomTypography>
                  </div>
                  {console.log("data1111", data)}
                  <img src={data.arrow} alt="arrow" />
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </CustomSwiper>
    </div>
  );
};

export default CaseStudies;
