import React from "react";
import { useParams } from "react-router-dom";
import BlogDetails from "../components/Blogs/BlogDetails";

function Blogs(props) {
  const { slug } = useParams();
  console.log("slug", slug);
  return <BlogDetails />;
}

export default Blogs;
