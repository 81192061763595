import React from "react";
import CustomSwiper from "../../shared/CustomSwiper";
import { makeStyles } from "@mui/styles";
import { Autoplay } from "swiper";
import { SwiperSlide } from "swiper/react";
import savers from "../../assets/images/savers.png";
import microsoft from "../../assets/images/Microsoft.png";
import starbucks from "../../assets/images/starbucks.png";
import texas from "../../assets/images/texaslogo.png";
import lemonade from "../../assets/images/lemonade.png";
import abnormal from "../../assets/images/abnormal.svg";
import wb from "../../assets/images/wb.svg";
import weWork from "../../assets/images/we-work.png";
import dart from "../../assets/images/dart.png";
import mio from "../../assets/images/mio.png";
import century from "../../assets/images/century.svg";
import operators from "../../assets/images/operators.png";
import frontline from "../../assets/images/front-line.webp";
import att from "../../assets/images/att.webp";
import emaar from "../../assets/images/emaar.png";
import alaska from "../../assets/images/alaska-airlines.png";
import colliers from "../../assets/images/Colliers.png";
import cbre from "../../assets/images/cbre.png";
import { Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  logoSlider: {
    padding: "100px 0 0",
    [theme.breakpoints.down("xl")]: {
      padding: "90px 0 0",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "70px 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px  0px 0",
    },
    "& .swiper-wrapper": {
      alignItems: "center",
    },
    "& .swiper-pagination": {
      display: "none",
    },
    "& .swiper-button-prev, .swiper-button-next": {
      display: "none",
    },
  },
  logoWrapper: {
    "& img": {
      maxHeight: 100,
      filter: "grayscale(100%)",
      [theme.breakpoints.down("lg")]: {
        maxHeight: 70,
      },
      [theme.breakpoints.down("sm")]: {
        maxHeight: 56,
      },
    },
    "&:hover": {
      "& img": {
        filter: "unset",
      },
    },
  },
}));

const logo = [
  {
    logo: starbucks,
  },
  {
    logo: savers,
  },
  {
    logo: microsoft,
  },
  {
    logo: weWork,
  },
  {
    logo: lemonade,
  },
  {
    logo: emaar,
  },
  {
    logo: texas,
  },
  {
    logo: mio,
  },
  {
    logo: alaska,
  },
  {
    logo: abnormal,
  },
  {
    logo: wb,
  },
  {
    logo: colliers,
  },
  {
    logo: att,
  },
  {
    logo: cbre,
  },
  {
    logo: dart,
  },
  {
    logo: frontline,
  },
  {
    logo: operators,
  },
  {
    logo: century,
  },
];

const LogoSlider = () => {
  const classes = useStyles();

  return (
    <div>
      <CustomSwiper
        className={classes.logoSlider}
        navigation={{ clickable: true }}
        pagination={{ clickable: true }}
        spaceBetween= {30}
        modules={[Autoplay]}
        speed={5000}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          0: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          576: {
            slidesPerView: 3,
           
          },
          768: {
            slidesPerView: 4.5,
            
          },
          992: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 7,
          },
          1500: {
            slidesPerView: 8.5,
            spaceBetween: 40,
          },
        }}
      >
        {logo.map((data, i) => (
          <SwiperSlide key={i} className={classes.solutionSlide}>
            <Stack className={classes.logoWrapper} justifyContent="center" alignItems="center" height="100%">
              <img src={data?.logo} alt="logos" />
            </Stack>
          </SwiperSlide>
        ))}
      </CustomSwiper>
    </div>
  );
};

export default LogoSlider;
